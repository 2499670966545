import React from "react";
import left from "../../assets/img/supply-left-img.png";
import right from "../../assets/img/Infographic-new.png";

function Section2({ isIpad = false }) {
  return (
    <div className="section2-body">
      <div className="supply-padding">
        <div className="supply-spacing">
          <div className="seciton2-top">
            <div className="top-left">
              <div className="heading">
                <span style={{ display: "grid" }}>
                  <span className="heading-supply-chain">
                    AI-Powered Supply Chain Resiliency Tool
                  </span>
                </span>
                <p className="text">
                  Empower your supply chain resilience with our AI-driven tool.
                  It precisely measures Geopolitical, Climate, and Supplier
                  risks, leveraging cutting-edge gen-AI to process 10Mn+ global
                  news stories and reports. This enables precise risk scoring
                  for affected trade lanes, contextualizing a comprehensive risk
                  measurement framework relevant to your organization.
                </p>
              </div>
            </div>

            {!isIpad && (
              <div className="top-right">
                <div className="image">
                  <img loading="lazy" src={right} alt="" />
                </div>
              </div>
            )}
          </div>
          <div className="section2-bottom">
            {!isIpad && (
              <div className="bottom-left">
                <div className="image">
                  <img loading="lazy" src={left} alt="" />
                </div>
              </div>
            )}
            <div className="bottom-right">
              <div className="heading-supply-chain">
                <span>Sustainable Supply Chain</span>
                <p className="text">
                  Unlock the power of sustainable supply chains. Explore
                  impactful strategies for integrating ethics and the
                  environment into your operations. We have been working with
                  industry leaders helping them champion sustainable practices
                  in their supply chain management.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
