import infoGraphic from "../../assets/img/Aadi/AADI INFO 3.png"

const NewSection = () => {
  return (
    <>
      <div className="aadi-newSection">
        {/* <div className="body">
          <div className="main">
            <div className="heading">AaDI’s AI Stack</div>
            <div className="subHeading">
              Powering Intelligence across Workflows
            </div>
            <div className="text1">
              AaDi’s AI stack has four Pillars and brings together connected
              power and intelligence of agentic workflows, knowledge graphs,
              mind map engines, LLMs and AI
            </div>
          </div>
          <div className="boxes-main">
            <div className="boxes">
              {AadiDescriptionData.map(
                ({ id, heading, point1, point2, point3, point4 }) => (
                  <NewSectionBox
                    key={id}
                    heading={heading}
                    point1={point1}
                    point2={point2}
                    point3={point3}
                    point4={point4}
                  />
                )
              )}
            </div>
          </div>
         
        </div> */}
        <div className="main">
          <div className="heading">AaDi’s AI Stack</div>
          <div className="subHeading">
            Powering Intelligence across Workflows
          </div>
          <div className="text1">
            AaDi’s AI stack has four Pillars and brings together connected power
            and intelligence of agentic workflows, knowledge graphs, mind map
            engines, LLMs and AI
          </div>
          <img src={infoGraphic} alt="Aays_Finance Co-Pilot" />
        </div>
      </div>
    </>
  );
};

export default NewSection;
