import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/MS-Solutions-Partner-rect.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

function MicrosoftSolutionsPartnerAzure() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

useEffect(() => {

  document.title = "Microsoft Solutions Partner for Azure";
  document.getElementsByTagName("META")[3].content =
    "Microsoft Solutions Partner. From Gold Partner to Azure Solutions Partner, we're dedicated to cutting-edge technology and innovation.";
})
const data = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "All Blogs",
    url: "/Blog",
  },
];

  return (
    <div id="homePage">
      <Helmet>
        <meta name="keywords" content=" Microsoft Solutions Partner" />
      </Helmet>

      <Navbar bg="black" />
      <GoogleAnalytics/>

      <div className="GenAi-body">
        <div className="margin-blogMore">
          <div className="GenAi-main">
          <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
            <h1 className="main-heading">
              {" "}
              Renewing our partnership with Microsoft: From Gold to Azure
              Solutions Partner
            </h1>

            <img
              className="blogImg"
              style={{ filter: "grayscale(1)" }}
              src={GenAiBanner}
              alt="blog1"
            />

            <div className="imgContent">
              <p
                style={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
                className="heading-content"
              >
                The Microsoft Partner Program underwent a transformation,
                transitioning from Microsoft Partner Network to the Microsoft
                Cloud Partner Program, and so have we in our partnership.
              </p>

              <p className="heading-content">
                Previously Gold Partners, we have now renewed our partnership as
                Solution Partners with Microsoft, specializing in{" "}
                <a
                  style={{
                    color: "#E67D1C",
                    width: "auto",
                    display: "inline-block",
                  }}
                  href="https://partner.microsoft.com/en-rs/asset/collection/solutions-partner-for-data-and-ai-azure#/"
                >
                  Azure Data &amp; Analytics
                </a>{" "}
                and{" "}
                <a
                  style={{
                    color: "#E67D1C",
                    width: "auto",
                    display: "inline-block",
                  }}
                  href="https://partner.microsoft.com/en-US/asset/collection/solutions-partner-for-digital-and-app-innovation-azure#/"
                >
                  Azure Digital &amp; App Innovation
                </a>
                .
              </p>

              <p className="heading-content">
                This reaffirms our dedication to cutting-edge technologies and
                our unwavering focus on delivering outstanding solutions to our
                valued clients.
              </p>

              <p className="heading-content">
                In addition, we invest in our culture and people to thrive in
                this complex ecosystem. As a team of experienced professionals
                with diverse backgrounds, we focus on functional expertise and
                collaborate with business and functional stakeholders. We
                develop analytics projects with functional relevance. This
                investment in our people and culture translates into powerful
                insights for our clients. This helps them capitalize on emerging
                opportunities and address business threats.
              </p>
              <p className="heading-content">
                We are excited to embark on this renewed journey with Microsoft,
                as we continue to lead the way in excellence and innovation.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default MicrosoftSolutionsPartnerAzure;
