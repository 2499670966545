import React from "react";
import { CustomerSuccessStoriesData } from "../../constant/CustomerSuccessStoriesData";
import img1 from "../../assets/img/finance-img1.png";
import img2 from "../../assets/img/finance-img2.png";
import img3 from "../../assets/img/finance-img3.png";
import img4 from "../../assets/img/finance-img4.png";
import img5 from "../../assets/img/finance-img5.png";
import img6 from "../../assets/img/finance-img6.png";
import img7 from "../../assets/img/finance-img7.png";
import img8 from "../../assets/img/finance-img8.png";
import infographic1 from "../../assets/img/finance-page-Infographic-1.png";
import infographic2 from "../../assets/img/finance-page-Infographic-2.png";
import infographic3 from "../../assets/img/finance-page-Infographic-3.png";
import infographic4 from "../../assets/img/finance-page-Infographic-4.png";
import BottomBanner from "../../reuseLayout/BottomBanner"

import SectionReuseBox from "../SupplyChainLayout/SectionReuseBox";
import HomePageSection5 from "../HomePageLayout/HomePageSection5";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import corporateFinanceMobile2 from "../../assets/img/corporate-finance-mobile2.png";
import corporateFinanceMobile1 from "../../assets/img/corporate-finance-mobile1.png";
import { useMediaQuery } from "@mui/material";
import Section5 from "../AboutPageLayout/Section5";
import Section2Mobile from "../CustomerSuccessStoryLayout/Section2Mobile";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
import { Helmet } from "react-helmet";

const data = [
  {
    id: 1,
    heading: "MIS Reporting",
    content:
      "Harness the power of comprehensive data insights for informed decision-making.",
    redirectURL: "/customer-success-stories#success-story-page-section-3",
    image: img1,
  },
  {
    id: 2,
    heading: "NSV Forecasting",
    content:
      "Strategically plan with precise predictions of non-sales value trends.",
    redirectURL: "/customer-success-stories#success-story-page-section-3",
    image: img2,
  },
  {
    id: 3,
    heading: "FP&A Analytics",
    content:
      "Drive financial strategies with robust insights derived from data analysis.",
    redirectURL: "/customer-success-stories#success-story-page-section-3",
    image: img3,
  },
  {
    id: 4,
    heading: "Sales Positioning",
    content:
      "Stay agile and responsive with real-time updates on daily sales performance.",
    redirectURL:
      "/customer-success-stories/advanced-daily-sales-positioning-case-study",
    image: img4,
  },
  {
    id: 5,
    heading: "Inventory Order-to-Cash",
    content: "Track inventory flow seamlessly, enhancing revenue management.",
    redirectURL: "/customer-success-stories/inventory-optimization-global-CPG",
    image: img5,
  },
  {
    id: 6,
    heading: "Accounts Receivable",
    content:
      "Maximize revenue flow through effective management of receivables.",
    redirectURL:
      "/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics",
    image: img6,
  },
  {
    id: 7,
    heading: "Accounts Payable",
    content:
      "Simplify and improve payment handling processes for greater efficiency.",
    redirectURL:
      "/customer-success-stories/vendor-payments-optimization-case-study",
    image: img7,
  },
  {
    id: 8,
    heading: "Cash Flow Tracking",
    content:
      "Monitor and optimize financial health with comprehensive tracking.",
    redirectURL:
      "/customer-success-stories/working-capital-analytics-global-CPG",
    image: img8,
  },
];

function FinancePage() {
  const isMobile = useMediaQuery("(max-width:767px");

  return (
    <div id="homePage">
      <Navbar />

      <Helmet>
        <meta
          name="keywords"
          content="Aays, Aays Analytics, Aays Finance analytics Solutions, AI in finance, AI and analytics for finance, AI in Corporate Finance, Finance Analytics, Corporate Finance AI Solutions, AI tools for Finance, FP&A analytics"
        />

        <meta
          name="description"
          content="AI-driven solutions for corporate finance. Empower your controllers, FP&A teams, and CFOs with advanced finance analytics and AI tools."
        />
        <meta
          property="og:title"
          content="Finance Analytics and AI Solutions | Aays"
        />
        <meta
          property="og:image"
          content="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/SEO_Aays_Cash%20Analytics.png"
        />
      </Helmet>

      <div className="Finance-page-main">
        {/* // Section1 starts  */}
        <div className="Finance-section1">
          <div className="margin-box">
            <img
              loading="lazy"
              style={{
                width: "100%",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={infographic1}
              alt="infographic1"
            />
          </div>
        </div>

        <div className="Finance-section2-new">
          <div className="margin-box">
            <div className="heading">
              <span style={{ display: "grid" }}>
                <span className="heading-supply-chain">
                  AI-Driven FP&A Tools
                </span>
              </span>
              <p className="text">
                Boost your financial strategy using our AI-driven FP&A tools.
                These tools bring a whole new way of analyzing and predicting
                things super quickly and accurately. By using top-notch
                technology, we help your business get really precise
                predictions, giving you an edge in a fast-changing market.
              </p>
            </div>

            <div className="imgAlignCenter">
              <p
                style={{
                  textAlign: "center",
                  paddingBottom: "2rem",
                  fontWeight: "700",
                  fontSize: "18px",
                  letterSpacing: "0.3px",
                  lineHeight: "24px",
                }}
                className="text"
              >
                We offer end to end deployment and consolidation of machine
                learning models into FP&A processes
              </p>
              <img
                loading="lazy"
                style={{
                  width: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
                src={infographic2}
                alt="infographic1"
              />
            </div>
          </div>
        </div>

        <div className="Finance-section3-new">
          <div className="margin-box">
            <div className="heading">
              <span style={{ display: "grid" }}>
                <span className="heading-supply-chain">
                  Redefining Financial Controllership with AI and Analytics
                </span>
              </span>
              <p className="text">
                Enhance the strategic impact of your finance controllers with
                advanced analytics. Enable them to lead changes using extensive
                data and analytics in traditional accounting tasks like
                budgeting and management systems. Utilize sophisticated AI and
                analytics solutions to drive process improvements and shape
                strategies effectively.
              </p>
            </div>
            <div className="imgAlignCenter">
              <p
                style={{
                  textAlign: "center",
                  paddingBottom: "4rem",
                  display: "block",
                  fontWeight: "700",
                  fontSize: "18px",
                  letterSpacing: "0.3px",
                  lineHeight: "24px",
                }}
                className="text"
              >
                Controllership areas that are enabled by advanced analytics
              </p>

              {isMobile ? (
                <img
                  loading="lazy"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                  src={corporateFinanceMobile1}
                  alt="infographic1"
                />
              ) : (
                <img
                  loading="lazy"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                  src={infographic3}
                  alt="infographic1"
                />
              )}
            </div>
          </div>
        </div>

        <div className="Finance-section4-new">
          <div className="margin-box">
            <div className="heading">
              <span style={{ display: "grid" }}>
                <span className="heading-supply-chain">
                  CFO Empowerment for Strategic Business Partnerships
                </span>
              </span>
              <p className="text">
                Equip your CFOs to establish impactful business partnerships.
                Provide them with tools to strategically use data, rigorously
                evaluate performance, and align financial plans for
                comprehensive insights. Witness a transformative shift as CFOs
                become architects of business success.
              </p>
            </div>
            <div className="imgAlignCenter">
              {isMobile ? (
                <img
                  loading="lazy"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                  src={corporateFinanceMobile2}
                  alt="infographic1"
                />
              ) : (
                <img
                  loading="lazy"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                  src={infographic4}
                  alt="infographic1"
                />
              )}
            </div>
          </div>
        </div>

        {/* // section3 ends  */}

        {/* // section4 starts  */}

        {isMobile ? (
          <Section2Mobile
            data={data}
            heading="Cutting-Edge Commercial & Finance Solutions We Offer"
          />
        ) : (
          <div className="Finance-section4">
            <div className="margin-box">
              <div className="supply-spacing">
                <h1>Cutting-Edge Commercial & Finance Solutions We Offer </h1>

                <div className="flexContainer">
                  {data.map((item) => (
                    <SectionReuseBox
                      picture={item.image}
                      heading={item.heading}
                      content={item.content}
                      redirectURL={item.redirectURL}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* // section4 ends  */}

        {/* // section5 starts  */}

        <div className="Finance-section5">
          {!isMobile ? (
            <Section5 data={CustomerSuccessStoriesData} tag="commercial" />
          ) : (
            <HomePageSection5
              data={CustomerSuccessStoriesData}
              tag="commercial"
              show="true"
            />
          )}
        </div>
        {/* // section5 ends  */}

        {/* // section6 starts  */}
        <div className="Finance-section6">
          <div className="margin-box">
            {/* <div className="supply-spacing">
              <span className="more">More Capabilities</span>
              <div className="flexContainer">
                <FinancePageReuse title="AI led Innovation" />
                <FinancePageReuse title="Visualization & Reporting" />
               
              </div>
            </div> */}
          </div>
        </div>
        {/* // section6 ends  */}
      </div>
      <BottomBanner contentChange={true} />
      <Footer />
    </div>
  );
}

export default FinancePage;
