import React from "react";
import {ReactComponent as ReadMoreIcon} from '../../assets/img/read-more-arrow.svg';

function SectionReuseBox(props) {
  return (
    <div
      style={{
        width: "250px",
      }}
    >
      <div
        style={{
          width: "250px",
          height: "187px",
        }}
      >
        <img loading="lazy" src={props.picture} alt="" />
      </div>

      <div
        className="box-color"
        style={{
          textAlign: "left",
          padding: '20px 4px 4px 10px',
          minHeight: "188px",
          backgroundColor: "black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
      <div 
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        <span
          style={{
            paddingBottom: "10px",
            fontWeight: "600",
            fontSize: "18px",
          }}
        >
          {props.heading}
        </span>
  
        <span
          style={{
            fontSize: "14px",
          }}
        >
          {props.content}
        </span>
      </div>


      <div 
        style={{ 
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'end'
        }}
      >
        <a style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
          }}
          className="read-more-icon"
          href={`${props.redirectURL}`}
        >
          <ReadMoreIcon width="50px" height="50px"/>
        </a>
      </div>

    </div>
    </div>
  );
}

export default SectionReuseBox;
