import React from 'react';
import './Section6a.scss';
import Marketmixmodellingg from '../../assets/img/Market Mix Modelling.jpg';

const Section6a = () => {
  return (
    <div className='main-mainss scroll-section'  id="section6a-customer-success-story">
        <div className='market'>Marketing</div>
        <p className='clasname'>Transforming customer engagement and enhancing brand strategies with our AI-driven marketing solutions for leading enterprises worldwide.
</p>
    <div className="applicatio">
      <div className="images-images">
        <img src={Marketmixmodellingg} alt="Working Capital Analytics" className="imging" />
      </div>
      <div className="textss">
        <div className="heades">Market Mix Modelling </div>
        <div className="header-para">
        <span className="header-text">
        See how a leading snack foods manufacturer is optimizing market mix strategies and using market mix modelling to boost sales, improve budget allocation, and enhance ROI across multiple channels..<br></br></span>
          <a href="customer-success-stories/market-mix-modelling-snacks-food-manufacturers">
          <button className='btns-btn'>Read More</button>
          </a>
          
        </div>
        
      </div>
    
     
    </div>
    </div>
  );
};

export default Section6a;
