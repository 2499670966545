import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const usePageSEO = ({
  title,
  description,
  keywords = [],
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl
}) => {
  useEffect(() => {
    document.title = title;
    // Set meta tags using react-helmet
    const metaTags = [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords.join(', ') },
      { property: 'og:title', content: ogTitle || title },
      { property: 'og:description', content: ogDescription || description },
      { property: 'og:image', content: ogImage },
      { property: 'og:url', content: ogUrl || window.location.href },
    ];
    // Update meta tags
    metaTags.forEach(tag => {
      const existingTag = document.querySelector(`meta[${tag.property ? 'property' : 'name'}="${tag.property ? tag.property : tag.name}"]`);
      if (existingTag) {
        existingTag.setAttribute('content', tag.content);
      } else {
        const newTag = document.createElement('meta');
        newTag.setAttribute(tag.property ? 'property' : 'name', tag.property ? tag.property : tag.name);
        newTag.setAttribute('content', tag.content);
        document.head.appendChild(newTag);
      }
    });

    // Clean up function
    return () => {
      // Optionally, remove meta tags if necessary
    };
  }, [title, description, keywords, ogTitle, ogDescription, ogImage, ogUrl]);

  return (
    <Helmet>
      {/* Example of setting title with react-helmet */}
      <title>{title}</title>
      {/* Add other meta tags as needed */}
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl || window.location.href} />
    </Helmet>
  );
};

export default usePageSEO;
