import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import supplyImg1 from "../assets/img/Supply Chain.png";
import supplyImg2 from "../assets/img/inventory-case-studies.jpg";
import supplyImg4 from "../assets/img/Innovation-Analytics-main-page-case-study.png";
import aiToolForController from "../assets/img/AI Tool for Controllers.png";
import workingCapitalAnalytics from "../assets/img/working-capita-indi-case-study.jpg";
import vendorPayment from "../assets/img/vendorPayment-caseStudy.png";
import accountReceivable from "../assets/img/accountReceivable-caseStudy.png";
import vendorRisk from "../assets/img/Vendor Risk Management.png";
import dailySalesPositioning from "../assets/img/DailySalesPositioning-case-study.jpg";
import primeCost from "../assets/img/primeCost-caseStudy.png";
import Section1 from "../pageLayout/CustomerSuccessStoryLayout/Section1";
import Section2 from "../pageLayout/CustomerSuccessStoryLayout/Section2";
import Section3 from "../pageLayout/CustomerSuccessStoryLayout/Section3";
import Section4 from "../pageLayout/CustomerSuccessStoryLayout/Section4";
import Section5 from "../pageLayout/CustomerSuccessStoryLayout/Section5";
import Section6a from "../pageLayout/CustomerSuccessStoryLayout/Section6a";
import Footer from "../nav/Footer";
import Navbar from "../nav/NavBar";
import { Helmet } from "react-helmet";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import mobileBannerImage from "../assets/img/customer-success-stories-banner-right.png";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import supplChain from "../assets/img/supply-chain-white-new-icon.svg";
import finance from "../assets/img/finance-white-new-icon.svg";
import marketing from "../assets/img/operations-white-new-icon.svg";
import Section2Mobile from "../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";
import supplyChainChange from "../assets/img/supply-chain-white-icon.svg";
import financeChange from "../assets/img/finance-white-icon.svg";
import marketingChange from "../assets/img/operations-white-icon.svg";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import { ResourceData } from "../constant/ResourceData";
import BottomBanner from "../reuseLayout/BottomBanner";

const SupplyChainData = [
  {
    id: 1,
    heading: "Supply Chain",
    content:
      "How a global CPG conglomerate strengthened its supply chain with optimised supply planning and improved AI-enabled demand forecasting",
    redirectURL:
      "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
    image: supplyImg1,
  },
  {
    id: 2,
    heading: "Inventory Analytics",
    content:
      "How Aays helped a global CPG company enhance its inventory management processes",
    redirectURL: "/customer-success-stories/inventory-optimization-global-CPG",
    image: supplyImg2,
  },
  {
    id: 3,
    heading: "Innovation Analytics",
    content:
      "How a CPG conglomerate leveraged advanced analytics to uncover the value of innovation and assess the impact of new product launches",
    redirectURL:
      "/customer-success-stories/analytics-to-assess-impact-of-innovation",
    image: supplyImg4,
  },
];

const financeData = [
  {
    id: 1,
    heading: "AI Tool For Controllers",
    content:
      "Equipping finance controllers with an AI-powered tool for faster closure of books and outlier detection",
    image: aiToolForController,
    redirectURL:
      "/customer-success-stories/ai-powered-tool-for-finance-controllers",
  },
  {
    id: 2,
    heading: "Working Capital Analytics",
    content:
      "Achieving financial success: How a global CPG company enhanced working capital efficiency with advanced analytics",
    class: "content-box-section3",
    image: workingCapitalAnalytics,
    redirectURL:
      "/customer-success-stories/working-capital-analytics-global-CPG",
  },
  {
    id: 3,
    heading: "Vendor Payments",
    content:
      "Leveraging AI-based predictive models to optimize vendor payments for a multi-billion dollar consumer goods company",
    class: "content-box-section3",
    image: vendorPayment,
    redirectURL:
      "/customer-success-stories/vendor-payments-optimization-case-study",
  },
  {
    id: 4,
    heading: "Account Receivable",
    content:
      "How a global CPG conglomerate strengthened its accounts receivable function by leveraging advanced analytics",
    class: "content-box-section3",
    image: accountReceivable,
    redirectURL:
      "/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics",
  },
];

const OperationsData = [
  {
    id:1,
    image: vendorRisk,
    heading: "Vendor Risk Management",
    content:
      "Managing vendor risks in a complex supply chain: Case study of a global consumer goods manufacturer",
    redirectURL: "/customer-success-stories/vendor-risk-management-case-study",
  },
  {
    id:2,
    image: dailySalesPositioning,
    heading: "Daily Sales Positioning",
    content:
      "Normalising daily sales position leveraging advanced smoothening and forecasting techniques",
    redirectURL:
      "/customer-success-stories/advanced-daily-sales-positioning-case-study",
  },
  {
    id:3,
    image: primeCost,
    heading: "Prime Cost",
    content:
      "Driving strategic initiatives through enhanced prime cost visibility: Success story of a global CPG company",
    redirectURL:
      "/customer-success-stories/prime-cost-visibility-global-CPG-success-story",
  },
];

const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + (90 * $(window).height()) / 100);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page

  $(window).scrollTop(y - (90 * $(window).height()) / 100);
};

function CustomerSuccessStory() {
  const [sectionVisibility, setSectionVisibility] = useState({
    caseStudySection1Visible: false,
    caseStudySection2Visible: false,
    caseStudySection3Visible: false,
    caseStudySection4Visible: false,
  });
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  useEffect(() => {
    document.title = "Aays | Customer Success Stories";
    document.getElementsByTagName("META")[3].content =
      "Explore transformative analytics in supply chain, commercial finance, and operations. Witness the power of data-driven solutions through impactful case studies.";
  });
  useEffect(() => {
    const observer = new window.IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.id;
          if (entry.isIntersecting) {
            setSectionVisibility((prevState) => ({
              ...prevState,
              [sectionId + "Visible"]: true,
            }));
            // console.log(`${sectionId} is visible`);
          } else {
            setSectionVisibility((prevState) => ({
              ...prevState,
              [sectionId + "Visible"]: false,
            }));
          }
        });
      },
      { root: null, threshold: 0.5 }
    );

    const sections = document.querySelectorAll(".section");
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);
  const Data = [
    {
      id: 1,
      image: supplChain,
      change: supplyChainChange,
      url: "#caseStudySection2",
      visible: sectionVisibility.caseStudySection2Visible,
    },
    {
      id: 2,
      image: finance,
      change: financeChange,
      url: "#caseStudySection3",
      visible: sectionVisibility.caseStudySection3Visible,
    },
    {
      id: 3,
      image: marketing,
      change: marketingChange,
      url: "#caseStudySection4",
      visible: sectionVisibility.caseStudySection4Visible,
    },
  ];

  return (
    <div id="homePage" className="case-studies-pages-cls">
      <Helmet>
        <meta
          name="keywords"
          content="Supply chain analytics success stories, commercial finance analytics case studies, operations analytics case studies "
        />
      </Helmet>
      <Navbar />
      <GoogleAnalytics />

      <div className="section" id="caseStudySection1">
        {isMobile ? (
          <NewHeaderSectionMobile
            text1="Customer Success Stories"
            subtext1="Unveiling the Impact of Our Solutions"
            subtext2="Through Inspiring Customer Journeys"
            picture={mobileBannerImage}
          />
        ) : isIpad ? (
          <IpadHeaderSection
            text1="Customer Success Stories"
            subtext1="Unveiling the Impact of Our Solutions"
            subtext2="Through Inspiring Customer Journeys"
            picture={mobileBannerImage}
          />
        ) : (
          <NewHeaderSection
            text1="Customer Success Stories"
            subtext1="Unveiling the Impact of Our Solutions"
            subtext2="Through Inspiring Customer Journeys"
            picture={mobileBannerImage}
          />
        )}
      </div>

      <MiddleBanner text="Discover how world leaders leverage aays' solutions for increased agility, data-driven decisions, and profound business insights through AI and ML" />
     <div>
      {!isMobile && !isIpad && <Section1 />}
      {isMobile ? (
        <div style={{ paddingTop: "2.5rem", backgroundColor: "black" }}>
          <Section2Mobile
            heading="Supply Chain & Procurement"
            data={SupplyChainData}
          />
        </div>
      ) : (
        <div className="section" id="caseStudySection2">
       {/*  <Section1 /> */}
          <Section2 />
        </div>
      )}

      {isMobile ? (
        <Section2Mobile heading="Commercial & Finance" data={financeData} />
      ) : (
        <Section3 />
      )}

      {isMobile ? (
        <Section2Mobile heading="Operations" data={OperationsData} />
      ) : (
        <Section4 />
      )}
      <Section6a/>
     
      </div>

      <Section5 data={ResourceData} category="resources" />
      <BottomBanner contentChange={true} />
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      {!isMobile && !sectionVisibility.caseStudySection1Visible && (
        <div
          style={{
            position: "fixed",
            right: "0%",
            bottom: "40%",
            backgroundColor: "#bb1ccc",
            padding: "1rem",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            zIndex: 1,
            visibility: sectionVisibility.caseStudySection1Visible
              ? "hidden"
              : "visible",
            // display: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {Data.map((item) => (
              <a href={item.url}>
                <img
                  style={{
                    height: "35px",
                  }}
                  src={item.visible ? item.image : item.change}
                  alt="logos"
                />
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerSuccessStory;
