import { Helmet } from "react-helmet";
import { useEffect } from "react";
import $ from "jquery";
import Navbar from "../../../nav/NavBar";
import Footer from "../../../nav/Footer";
import "./newBlog.scss";
import "./newBlogResponsive.scss";
import aaysInvest from "../../../assets/img/Aays_Why invest in Gen AI now.jpg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";


const InvestInAI = () => {
  useEffect(() => {
    document.title =
      "Why invest in Gen AI now | AI Investment Stategies by Aays";
    document.getElementsByTagName("META")[3].content =
      "Know why Investing in Generative AI now is crucial for competitive advantage. Explore effective AI investment strategies and future of AI. ";
  });

    const data = [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "All Blogs",
        url: "/Blog",
      },
    ];

  return (
    <>
      <div id="homePage">
        <Helmet>
          <meta
            name="keywords"
            content="Aays Analytics, Aays, Why invest in Gen AI, AI investment strategies, AI investment strategies by Aays, AI investments, Future of AI "
          />
        </Helmet>

        <Navbar bg="black" />
        <div className="newBlog-body">
          <div className="newBlog-margin">
            <div className="newBlog-main">
              <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
              <div className="firstSection">
                <div className="main">
                  <h1 className="heading">
                    Why Invest in Generative AI Now? Exploring AI Investment
                    Strategies
                  </h1>
                  <div className="date-read-container">
                    <div className="date-container">
                      <div className="date"> Jul 8, 2024 </div>
                      <div className="read">
                        <AccessTimeIcon
                          style={{
                            fontSize: "14px",
                          }}
                        />{" "}
                        <span> 2 min read</span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="box-form investInAI-background"></div>
                  <div className="content1">
                    <div
                      style={{
                        paddingTop: "2rem",
                      }}
                      className="text1"
                    >
                      We are pleased to announce that Aays has been recognized
                      by the Financial Times and Statista in their prestigious
                      list of 500 High Growth Companies Asia-Pacific 2024,
                      featuring companies that have generated more than $1
                      million in revenue.
                    </div>
                    <div className="text1">
                      With an overall rank of 31st, we are also proud to be
                      ranked 1st in the management consulting category.
                    </div>
                  </div>
                </div>
              </div>

              <div className="quotes">
                <div className="main">
                  <div className="heading">
                    Understanding Tech Maturity Levels and Future of AI
                  </div>
                  <div className="content">
                    <p className="content1">
                      Generative AI is an emerging science, and its journey
                      towards achieving Artificial General Intelligence (AGI)
                      can be mapped through several key levels. Each level
                      represents a significant leap in its capabilities and
                      value creation potential.
                    </p>
                    {/* <p className="content2">
                      The report provides an in-depth analysis of the
                      employee-centric policies implemented by top companies,
                      focusing on creating exemplary work environments for data
                      scientists. The top indices based on which the employers
                      were evaluated were: Learning & Support, Productivity &
                      Engagement, Benefits & Well-being, Rewards & Excellence,
                      and Diversity & Inclusion. Read the complete{" "}
                      <a href="https://aimresearch.co/penetration-maturity-pema-matrix/top-data-engineering-service-providers-pema-quadrant-2024">
                        <span className="link">Report here</span>
                      </a>
                      .
                    </p> */}
                    <div className="image">
                      <img src={aaysInvest} alt="" />
                    </div>

                    <div className="heading">
                      Incremental Initiatives (Level 1): Utilising AI
                      initiatives as Co-Pilots
                    </div>

                    <p className="content2">
                      At this stage, AI systems function as competent
                      consultants or collaborators or co-pilots, assisting
                      humans in decision-making processes and enhancing
                      productivity. Value creation at this level is more in the
                      nature of productivity improvements with ROI in the range
                      of 10%-15% over a time frame of 1-3 years, enabled by
                      advanced Large Language Model (LLM) algorithms.
                    </p>

                    <p className="content2">
                      Applications of Incremental AGI include chatbots for
                      customer service, expert systems (Knowledge Copilots),
                      process automations, advanced demand forecasting, code
                      copilots, decision intelligence, hyper-personalized
                      recommendations, and content creation optimized for
                      various media.
                    </p>

                    <div className="heading">Transformative AGI (Level 2) </div>
                    <p className="content1">
                      The next stage, Transformative AGI or Level 3, elevates AI
                      to the status of an expert. Here, AI systems possess deep
                      domain knowledge and can perform complex tasks with high
                      accuracy and efficiency.{" "}
                    </p>

                    <p className="content2">
                      This can be possibly achieved in a time frame of 5-7
                      years, supported by enabling technologies like Edge AI, 5G
                      & beyond connectivity, and neuromorphic computing.{" "}
                    </p>
                    <p className="content2">
                      Use cases for Level 3 include risk management, autonomous
                      factories, semi-autonomous logistics, autonomous
                      warehousing, and content creation optimized for various
                      media. Edge AI brings processing capabilities closer to
                      data sources, reducing latency and improving real-time
                      decision-making.{" "}
                    </p>

                    <div className="heading">Disruptive AGI (Level 3) </div>
                    <div className="content1">
                      At this level, AI systems act as autonomous agents capable
                      of performing tasks independently and making strategic
                      decisions. The timeline is projected as 10+ years, with
                      enabling technologies such as quantum computing and
                      advanced materials for computing (e.g., superconductors).{" "}
                    </div>
                    <div className="content1">
                      Use cases for disruptive AGI include AI-powered
                      formulation creation, market simulation,
                      subscription-based business models, and advanced supply
                      chain optimization. Quantum computing offers unparalleled
                      processing power, enabling AI to solve problems that are
                      currently intractable.{" "}
                    </div>

                    <div className="heading">
                      The Transformative Force of AI Across Industries{" "}
                    </div>
                    <div className="content1">
                      Once AI surpasses Level 4 AGI, it will become a
                      transformative force in every industry. The potential
                      applications are limitless, from revolutionizing
                      manufacturing with self-optimizing factories to demand
                      forecasting with near-perfect accuracy in retail.{" "}
                    </div>

                    <div className="heading">
                      Embrace Gen-AI for Future Success: Optimize AI Investments{" "}
                    </div>
                    <div className="content1">
                      Organizations must embrace Gen-AI now to build the
                      necessary capabilities for a competitive edge in the
                      future. Investing in the right technologies and
                      understanding the different levels of AI maturity will be
                      crucial for leveraging the full potential of AI.{" "}
                    </div>
                    <div className="content-last">
                      As we see, the journey towards Artificial General
                      Intelligence is filled with opportunities for value
                      creation. By aligning investments with AI maturity levels,
                      organizations can harness the power of AI to drive
                      innovation, efficiency, and growth in the future of AI.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default InvestInAI;
