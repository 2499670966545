import React, { useState } from "react";
import $ from "jquery";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function ApplyJobs(props) {
  const [isloading, setisLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);

    var form = document.getElementById("ApplyJobsID");
    var formData = new FormData(form);

    $.ajax({
      url: "https://www.aaysinsight.com/asnotis/email/send",
      type: "POST",
      data: formData,
      success: function (data) {
        // clear form and show a success message
        setisLoading(false);
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
        }, 5000);
        document.getElementById("ApplyJobsID").reset();
        props.setOpenModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      },
      error: function () {
        setisLoading(false);
        alert("Something went wrong, Please try again later");
        props.setOpenModal(false);
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  };

  return (
    <>
      {props.openModal && (
        <div>
          <Modal
            open={props.openModal}
            onClose={() => props.setOpenModal(false)}
          >
            <div className="modal-box">
              <form
                id="ApplyJobsID"
                method="post"
                onSubmit={handleSubmit}
                enctype="multipart/form-data"
              >
                <div className="contact-container">
                  <div>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",alignContent:"center"}}>
                          <div className="contact-heading">Apply here</div>
                        <div onClick={()=> props?.setOpenModal(false)} style={{cursor: 'pointer'}}><HighlightOffIcon/></div>
                        </div>
                    <p>Please complete all fields.</p>
                  </div>

                  {/* email  */}
                  <div className="form__field">
                    <input
                      id="email-for-job"
                      className="input-field-landing font-family"
                      type="email"
                      name="email"
                      placeholder="Email address"
                      required
                    />
                  </div>

                  {/* name */}
                  <div className="form__field">
                    <input
                      id="name-for-job"
                      className="input-field-landing font-family"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </div>

                  {/* // phone  */}
                  <div className="form__field">
                    <input
                      id="phone-for-job"
                      className="input-field-landing font-family"
                      type="tel"
                      name="phone"
                      placeholder="Phone"
                      required
                    />
                  </div>

                  {/* // company  */}
                  <div className="form__field">
                    <input
                      id="message"
                      className="input-field-landing font-family "
                      type="text"
                      name="message"
                      placeholder="Position"
                      required
                      value={props.position}
                      // disabled={props.position ? true : false}
                    />
                  </div>

                  <div className="form__field">
                    <input
                      className="input-field-landing font-family "
                      onkeypress="return /[0-9.]/i.test(event.key)"
                      id="experiences-for-job"
                      type="tel"
                      name="experiences"
                      placeholder="Years of Experience"
                      required
                    />
                  </div>

                  <div className="form__field">
                    <input
                      className="input-field-landing font-family"
                      accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                      id="Updated_Cv-for-job"
                      type="file"
                      name="company"
                      placeholder="Company"
                      required
                    />
                  </div>
                </div>

                <div className="submit-btn">
                  <button type="submit" disabled={isloading}>
                    {isloading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span style={{ paddingLeft: "10px" }}>
                          Submitting...
                        </span>
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <img
                  src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/trustlogo.jpg"
                  alt="logo1"
                />
                <img
                  src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/nortonlogo.jpg"
                  alt="logo2"
                />
              </div>
            </div>
          </Modal>
        </div>
      )}

      <div>
        {successModal && (
          <Modal open={successModal} onClose={() => setSuccessModal(false)}>
            <div className="modal-box">
              <div style={{ width: "400px", padding: "1rem" }}>
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    marginBottom: "1rem",
                  }}
                >
                  Thank you for getting in touch! We appreciate you contacting
                  us.
                </div>
                <div style={{ fontSize: "18px", marginBottom: "2rem" }}>
                  One of our colleagues will get back to you soon. Have a great
                  day!
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <img
                    src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/trustlogo.jpg"
                    alt="logo1"
                  />
                  <img
                    src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/nortonlogo.jpg"
                    alt="logo2"
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default ApplyJobs;
