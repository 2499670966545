import React from 'react'
import Navbar from '../../nav/NavBar'
import img1 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0001.jpg'
import img2 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0002.jpg'
import img3 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0003.jpg'
import img4 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0004.jpg'
import img5 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0005.jpg'
import img6 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0006.jpg'
import img7 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0007.jpg'
import img8 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0008.jpg'
import img9 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0009.jpg'
import img10 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0010.jpg'
import img11 from '../../assets/pdfimg/Aays_Brochure_Final_web_page-0011.jpg'
import img13 from '../../assets/pdfimg/Aays_Brochure_Final_print_13.svg'
import img14 from '../../assets/pdfimg/Aays_Brochure_Final_print_14.svg'
import Footer from '../../nav/Footer'
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const TestDoc = () => {

  const downloadFinanceController = () => {
    fetch(`https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/Aays_Brochure_Final_web.pdf`)
      .then((response) => {
        console.log(response.status)
       return response.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `your-analytics-and-ai-solutions-partner${new Date().getTime()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading finance controller:", error);
      });
  };

  
  
  const imgData = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img6 },
    { img: img7 },
    { img: img8 },
    { img: img9 },
    { img: img10 },
    { img: img11 },
    { img: img13 }, 
    { img: img14 } // This is the 13th element

];
  return (
    <div style={{ height: '100%',background:"rgb(82,86,89)" }} id='homePage' className='campaning aaysbroucher'>
      <Navbar />
      <div className='marginAaysbroucher' style={{margin:"auto",width:"50%",display:"block",paddingTop:"6rem",paddingBottom:"1rem"}}>

        <div style={{display:"grid",gap:"10px"}}>
          {imgData.map((data,index) =>{
            return(
              <div key={index}>
                <img style={{width:"100%",boxShadow:"0px 0px 40px rgba(0,0,0,0,9)",objectFit:"contain"}} src={data.img} alt='imageData'/>
              </div>
            )
          })}
        </div>


      </div>
      <Footer/>

      <div onClick={downloadFinanceController} style={{width:"50px",height:"50px",backgroundColor:"#bb1ccc",position:"fixed",bottom:"10%",right:"5%",borderRadius:"100%",display:"grid",alignItems:"center",justifyContent:"center",alignContent:"center",cursor:"pointer"}}>
          <FileDownloadIcon sx={{color:"white"}}/>
      </div>

    </div>
  )
}

export default TestDoc
