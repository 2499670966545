import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./Components/pages/Home";
import { BrowserRouter as Router, Route, Routes,useLocation } from "react-router-dom";
import Dataengineering from "./Components/pages/Dataengineering";
import CustomerSuccessStory from "./Components/pages/CustomerSuccessStory";
import Blog from "./Components/pages/Blog";
import AIToolForControllers from "./Components/pages/CaseStudies/AI_Tool_For_Controllers";
import DataAnalyticsImplementationJourneyInBusinessAndFinance from "./Components/pages/AllBlogsPages/DataAnalyticsImplementationJourneyInBusinessAndFinance";
import AccountReceivable from "./Components/pages/CaseStudies/Account_Receivable";
import DailySalesPositioning from "./Components/pages/CaseStudies/Daily_Sales_Positioning";
import InnovationAnalytics from "./Components/pages/CaseStudies/Innovation_Analytics";
import InventoryAnalytics from "./Components/pages/CaseStudies/Inventory_Analytics";
import MarketMixModeling from "./Components/pages/CaseStudies/Market_Mix_Modeling";
import PrimeCost from "./Components/pages/CaseStudies/Prime_Cost";
import SupplyChain from "./Components/pages/CaseStudies/Supply_Chain";
import Optimisingmarketmixedstrats from "./Components/pages/CaseStudies/Optimisingmarketmixedstrats";
import VendorPayments from "./Components/pages/CaseStudies/Vendor_Payments";
import VendorRiskManagement from "./Components/pages/CaseStudies/Vendor_Risk_Management";
import WorkingCapitalAnalytics from "./Components/pages/CaseStudies/Working_Capital_Analytics";
import Newsroom from "./Components/pages/Newsroom";
import BlogMoreLayout from "./Components/pageLayout/BlogMoreLayout/BlogPages/BlogMoreLayout";
import GenAI from "./Components/pages/AllBlogsPages/GenAI";
import HowIsAiTransformingFpAITheConsumerGoodsIndustry from "./Components/pages/AllBlogsPages/HowIsAiTransformingFpAITheConsumerGoodsIndustry";
import TransformingFinanceFunctionWithAdvancedAnalytics from "./Components/pages/AllBlogsPages/TransformingFinanceFunctionWithAdvancedAnalytics";
import HowLargeEnterprisesAreDemocratisingDataScienceMlInTheCorporateFinanceSpace from "./Components/pages/AllBlogsPages/HowLargeEnterprisesAreDemocratisingDataScienceMlInTheCorporateFinanceSpace";
import TransformingPricingDecisionsInCPGCompanies from "./Components/pages/AllBlogsPages/TransformingPricingDecisionsInCPGCompanies";
import DataAnalyticsInConsumerPackagedGoods from "./Components/pages/AllBlogsPages/DataAnalyticsInConsumerPackagedGoods";
import SupplyChainAnalytics from "./Components/pages/AllBlogsPages/SupplyChainAnalytics";
import HowIsBigDataAutomotiveIndustry from "./Components/pages/AllBlogsPages/HowIsBigDataAutomotiveIndustry";
import MicrosoftSolutionsPartnerAzure from "./Components/pages/AllBlogsPages/MicrosoftSolutionsPartnerAzure";
import AaysInSingapore from "./Components/pages/AllBlogsPages/AaysInSingapore";
import MicrosoftGoldPartners from "./Components/pages/AllBlogsPages/MicrosoftGoldPartners";
import BusinessAnalyticsArchitecture from "./Components/pages/AllBlogsPages/BusinessAnalyticsArchitecture";
import DatabricksTipsForBeginners from "./Components/pages/AllBlogsPages/DatabricksTipsForBeginners";
import CommonMstakesPowerbibeginner from "./Components/pages/AllBlogsPages/CommonMstakesPowerbibeginner";
import ApacheSparkPerformanceOptimization from "./Components/pages/AllBlogsPages/ApacheSparkPerformanceOptimization";
import Contact from "./Components/pages/Contact";
import $ from "jquery";
import PrivacyPolicy from "./Components/pages/PrivacyPolicy";
import Pagenotfound from "./Components/pages/Pagenotfound";
import SupplyChainPage from "./Components/pages/SupplyChain";
import Finance from "./Components/pages/Finance";
import DataLed from "./Components/pages/DataLed";
import Ainnovations from "./Components/pages/Ainnovations";
import Automotive from "./Components/pages/Automotive";
import AboutUs from "./Components/pages/AboutUs";
import Career from "./Components/pages/Career";
import DataEngineer from "./Components/pages/OpenPositions/DataEngineer";
import DataArchitect from "./Components/pages/OpenPositions/DataArchitect";
import FullStackDeveloper from "./Components/pages/OpenPositions/FullStackDeveloper";
import SeniorManager from "./Components/pages/OpenPositions/SeniorManager";
import FinanceAnalyticsCapabilities from "./Components/pages/Campaign/FinanceAnalyticsCapabilities";
import AaysBrochure from "./Components/pages/Campaign/AaysBrochure";
import InventoryAnalytics1 from "./Components/pages/Campaign/InventoryAnalytics";

import Portal from "./Components/Portals/Portal";
import ModelPopup from "./Components/reuseLayout/ModelPopup";
import { IconButton } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { ModeOfTravelRounded } from "@mui/icons-material";
import ModalPopup from "./Components/reuseLayout/ModalPopup/ModalPopup";
import Aadi from "./Components/pages/Aadi/Aadi";
import Genai from "./Components/pages/AllBlogsPages/newBLog/Genai";
import DataEngineerLeader from "./Components/pages/AllBlogsPages/newBLog/DataEngineerLeader";
import { useMediaQuery, useTheme } from "@mui/material/";
import BestFirm from "./Components/pages/AllBlogsPages/newBLog/BestFirm";
import FT from "./Components/pages/AllBlogsPages/newBLog/FT";
import InvestInAI from "./Components/pages/AllBlogsPages/newBLog/InvestInAI";
import GenAiPriority from "./Components/pages/AllBlogsPages/newBLog/GenAiPriority";
import FoodAndBeverage from "./Components/pages/FoodAndBeverage";
import Retail from "./Components/pages/Retail";
import Manufacturing from "./Components/pages/Manufacturing";
import BottomBanner from "./Components/reuseLayout/BottomBanner";
import { Helmet } from "react-helmet";
import Leveragingai from "./Components/pages/AllBlogsPages/newBLog/Leveragingai";
import HowAiIsTransformingSupplyChain from "./Components/pages/AllBlogsPages/HowAiIsTransformingSupplyChain";
import TransformingInventoryManagement from "./Components/pages/AllBlogsPages/TransformingInventoryManagement";
import TheRoleofaimodernmarket from "./Components/pages/AllBlogsPages/newBLog/TheRoleofaimodernmarket";


function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const [showPopup, setShowPopup] = useState(false);

  const closeDiv = () => {
    sessionStorage.setItem("popupClosed", "true");
    setShowPopup(false);
  };

  const currentPath = window.location;
  const urlpath = currentPath.pathname;
  console.log(urlpath)

  const checkPath = () => {
    const url = currentPath.pathname;

    // Split the URL using '/' as the delimiter and get the second element (index 1)
    const blogsSegment = url.split("/")[1];

    const URLdata = `${"/" + blogsSegment}`;

    if (URLdata === "/") {
      $("#active_path_Home").addClass("active-home-cls");
    }

    if (URLdata === "/aays-decision-intelligence-aadi") {
      $("#active_path_Applications").addClass("active-application-cls");
    }
    if (URLdata === "/ai-and-data-engineering") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Data-Engineering_Active").addClass("activeNavLink");
    }
    if (URLdata === "/supply-chain-analytics") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Supply-Chain-Procurement_Active").addClass("activeNavLink");
    }
    if (URLdata === "ai-innovations-enterprise-solutions") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Supply-Chain-Procurement_Active").addClass("activeNavLink");
    }
    if (URLdata === "/ai-and-finance-analytics") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Supply-Chain-Procurement_Active").addClass("activeNavLink");

      <Helmet>
      <meta
        name="keywords"
        content="Aays, Aays Analytics, Aays Finance analytics Solutions, AI in finance, AI and analytics for finance, AI in Corporate Finance, Finance Analytics, Corporate Finance AI Solutions, AI tools for Finance, FP&A analytics"
      />
      <meta name="description" content="AI-driven solutions for corporate finance. Empower your controllers, FP&A teams, and CFOs with advanced finance analytics and AI tools." /> 
      <meta property="og:title" content="Finance Analytics and AI Solutions | Aays" />
      <meta property="og:image" content="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/SEO_Aays_Cash%20Analytics.png" />
      <title>Finance Analytics and AI Solutions | Aays</title>
    </Helmet>
    }
    if (URLdata === "/ai-solutions-cpg") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Supply-Chain-Procurement_Active").addClass("activeNavLink");
    }
    if (URLdata === "/ai-solutions-for-food-and-beverage-companies") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Supply-Chain-Procurement_Active").addClass("activeNavLink");
    }
    if (URLdata === "/ai-solutions-retail") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Supply-Chain-Procurement_Active").addClass("activeNavLink");
    }
    if (URLdata === "/ai-and-analytics-in-manufacturing") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Supply-Chain-Procurement_Active").addClass("activeNavLink");
    }
    if (URLdata === "/ai-and-analytics-in-automotive-industry") {
      $("#active_path_Solutions").addClass("active-solution-cls");
      $("#Supply-Chain-Procurement_Active").addClass("activeNavLink");
    }
    if (URLdata === "/customer-success-stories") {
      $("#active_path_Resources").addClass("active-resorces-cls");
      $("#Case-Studies_Active").addClass("activeNavLink");
    }

    if (URLdata === "/Blog") {
      $("#active_path_Resources").addClass("active-resorces-cls");
      $("#Blog_Active").addClass("activeNavLink");
    }
    if (URLdata === "/newsroom") {
      $("#active_path_Resources").addClass("active-resorces-cls");
      $("#Newsroom_Active").addClass("activeNavLink");
    }
    if (URLdata === "/blogs") {
      $("#active_path_Resources").addClass("active-resorces-cls");
    }
    if (URLdata === "/customer-success-stories") {
      $("#active_path_Resources").addClass("active-resorces-cls");
    }
    if (URLdata === "/about") {
      $("#active_path_About").addClass("active-resorces-cls");
    }
    if (URLdata === "/career") {
      $("#active_path_Career").addClass("active-resorces-cls");
    }
  };

  // setTimeout(() => {
  //     setShowPopup(false);
  //   sessionStorage.setItem("popupClosed", "true");

  //   }, 6000)

  useEffect(() => {
    checkPath();
    const popupClosed = sessionStorage.getItem("popupClosed") === "true";
    if (popupClosed) {
      setShowPopup(false);
    } else {
      const timeout = setTimeout(() => {
        setShowPopup(true);
      }, 5000); // 5 seconds
      // Clean up the timeout on component unmount
      return () => {
        // alert("timeout cleared")
        clearTimeout(timeout);
      };
    }
  }, []);

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=UA-48468419-1";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-48468419-1');
      gtag('config', 'AW-342608069');
    `;
    document.head.appendChild(script2);

    const script3 = document.createElement("script");
    script3.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-48468419-1');
    `;
    document.head.appendChild(script3);

    // Cleanup scripts on component unmount
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
      document.head.removeChild(script3);
    };
  }, []);
 
  return (
    <div className="App">
     { urlpath === "/" && !isMobile && !showPopup && (
        <div
          style={{
            position: "fixed",
            right: "100px",
            bottom: "90px",
            backgroundColor: "#bb1ccc",
            borderRadius: "50%",
            zIndex: "999",
          }}
        >
          <IconButton
            onClick={() => {
              setShowPopup(!showPopup);
            }}
          >
            <NotificationsActiveIcon
              sx={{
                color: "white",
                fontSize: "30px",
              }}
            />
          </IconButton>
        </div>
      )}

      {urlpath === "/" && !isMobile && showPopup && (
        <>
          <ModalPopup
            closeDiv={closeDiv}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
          />
        </>
      )}
      

      <Router forceRefresh={true}>
        <Routes>
          {/* <Route path = "/test" element = {<BottomBanner />} /> */}
          <Route path="/" element={<Home />} />{" "}``
          <Route
            path="/blogs/prioritizing-ai-and-gen-ai-use-cases"
            element={<GenAiPriority />}
          />{" "}
          <Route
            path="/blogs/why-invest-in-gen-ai-now"
            element={<InvestInAI />}
          />
          <Route
           path="/blogs/ai-ml-for-business-decision-making"
           element={<Leveragingai/>}
           />
           <Route 
           path="/blogs/ai-driven-personalization-in-marketing"
           element={<TheRoleofaimodernmarket/>}
           />
           <Route
           path="/blogs/ai-transforming-supply-chain-management"
           element={<HowAiIsTransformingSupplyChain/>}
           />
           <Route
           path="/blogs/ai-inventory-management"
           element={<TransformingInventoryManagement/>}
           />

          <Route
            path="/aays-listed-on-financial-times-high-growth-companies"
            element={<FT />}
          />
          <Route
            path="/50-best-firms-for-data-scientists-2024"
            element={<BestFirm />}
          />
          <Route
            path="/gen-ai-seasoned-vendor-aim-research"
            element={<Genai />}
          />
          <Route
            path="/aim-ranks-aays-a-leader-in-data-engineering-service-providers"
            t
            element={<DataEngineerLeader />}
          />
          <Route path="/aays-decision-intelligence-aadi" element={<Aadi />} />
          <Route path="/portal" element={<Portal />} />
          <Route path="/Positions/data-engineer" element={<DataEngineer />} />
          <Route path="/Positions/data-architect" element={<DataArchitect />} />
          <Route
            path="/Positions/full-stack-developer"
            element={<FullStackDeveloper />}
          />
          <Route path="/Positions/senior-manager" element={<SeniorManager />} />
          <Route path="/home.html" element={<Home />} />
          <Route
            path="/ai-and-data-engineering"
            element={<Dataengineering />}
          />
          <Route
            path="/customer-success-stories"
            element={<CustomerSuccessStory />}
          />
           <Route path="/Blog" element={<Blog />} /> 
              
          <Route path="/BlogMore" element={<BlogMoreLayout />} />
          {/* <Route path="/blogs/genai" element={<GenAI />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/supply-chain-analytics" element={<SupplyChainPage />} />
          <Route path="/ai-and-finance-analytics" element={<Finance />} />
          <Route path="/ai-solutions-cpg" element={<DataLed />} />
          <Route
            path="/ai-solutions-for-food-and-beverage-companies"
            element={<FoodAndBeverage />}
          />
          <Route path="/ai-solutions-retail" element={<Retail />} />
          <Route
            path="/ai-innovations-enterprise-solutions"
            element={<Ainnovations />}
          />
          <Route
            path="/ai-and-analytics-in-automotive-industry"
            element={<Automotive />}
          />
          <Route
            path="/ai-and-analytics-in-manufacturing"
            element={<Manufacturing />}
          />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/career" element={<Career />} />
          <Route path="*" element={<Pagenotfound />} />
          <Route
            path="/Market-Mix-Modelling-Analytics.pdf"
            element={<FinanceAnalyticsCapabilities />}
          />
          <Route
            path="/Inventory-Analytics.pdf"
            element={<InventoryAnalytics1 />}
          />
          <Route
            path="/your-analytics-and-ai-solutions-partner"
            element={<AaysBrochure />}
          />
          <Route
            path="/customer-success-stories/ai-powered-tool-for-finance-controllers"
            element={<AIToolForControllers />}
          />
          <Route
            path="/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics"
            element={<AccountReceivable />}
          />
          <Route
            path="/customer-success-stories/advanced-daily-sales-positioning-case-study"
            element={<DailySalesPositioning />}
          />
          <Route
            path="/customer-success-stories/analytics-to-assess-impact-of-innovation"
            element={<InnovationAnalytics />}
          />
          <Route
            path="/customer-success-stories/inventory-optimization-global-CPG"
            element={<InventoryAnalytics />}
          />
          <Route
            path="/customer-success-stories/market-mix-modelling-snacks-food-manufacturer"
            element={<MarketMixModeling />}
          />
          <Route
            path="/customer-success-stories/prime-cost-visibility-global-CPG-success-story"
            element={<PrimeCost />}
          />
          <Route
            path="/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting"
            element={<SupplyChain />}
          />
          <Route
            path="/customer-success-stories/vendor-payments-optimization-case-study"
            element={<VendorPayments />}
          />
          <Route
            path="/customer-success-stories/vendor-risk-management-case-study"
            element={<VendorRiskManagement />}
          />
          <Route
          path="/customer-success-stories/market-mix-modelling-snacks-food-manufacturers"
          element ={<Optimisingmarketmixedstrats/>}
          />
          <Route
            path="/customer-success-stories/working-capital-analytics-global-CPG"
            element={<WorkingCapitalAnalytics />}
          />
          <Route
            path="/blogs/data-analytics-implementation-journey-in-business-and-finance"
            element={<DataAnalyticsImplementationJourneyInBusinessAndFinance />}
          />
          <Route
            path="/blogs/how-is-ai-transforming-fp-a-in-the-consumer-goods-industry"
            element={<HowIsAiTransformingFpAITheConsumerGoodsIndustry />}
          />
          <Route
            path="/blogs/transforming-finance-function-with-advanced-analytics"
            element={<TransformingFinanceFunctionWithAdvancedAnalytics />}
          />
          <Route
            path="/blogs/how-large-enterprises-are-democratising-data-science-ml-in-the-corporate-finance-space"
            element={
              <HowLargeEnterprisesAreDemocratisingDataScienceMlInTheCorporateFinanceSpace />
            }
          />
          <Route
            path="/blogs/data-analytics-in-consumer-packaged-goods"
            element={<DataAnalyticsInConsumerPackagedGoods />}
          />
          <Route
            path="/blogs/supply-chain-analytics"
            element={<SupplyChainAnalytics />}
          />
          <Route
            path="/blogs/How-is-big-data-and-analytics-helping-the-automotive-industry"
            element={<HowIsBigDataAutomotiveIndustry />}
          />
          <Route
            path="/blogs/transforming-pricing-decisions-in-CPG-companies"
            element={<TransformingPricingDecisionsInCPGCompanies />}
          />
          <Route
            path="/blogs/microsoft-solutions-partner-azure"
            element={<MicrosoftSolutionsPartnerAzure />}
          />
          <Route
            path="/blogs/aays-in-singapore"
            element={<AaysInSingapore />}
          />
          <Route
            path="/blogs/microsoft-gold-partners"
            element={<MicrosoftGoldPartners />}
          />
          <Route
            path="/blogs/business-analytics-architecture"
            element={<BusinessAnalyticsArchitecture />}
          />
          <Route
            path="/blogs/databricks-tips-for-beginners"
            element={<DatabricksTipsForBeginners />}
          />
          <Route
            path="/blogs/common-mistakes-made-by-a-Powerbi-beginner"
            element={<CommonMstakesPowerbibeginner />}
          />
          <Route
            path="/blogs/apache-spark-performance-optimization"
            element={<ApacheSparkPerformanceOptimization />}
          />
          <Route path="/newsroom" element={<Newsroom />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
