export const ProductData = [
    {
        title: "CFO Reporting",
        description: "Manufacturing Dashboard",
        url: "https://product-url.com/"
    },
    {
        title: "P&L Reporting",
        description: "Flux Dashboard",
        url: "https://product-url.com/"
    },
    {
        title: "P&L Reporting",
        description: "Flux Dashboard",
        url: "https://product-url.com/"
    },
    {
        title: "Working Capital Optimisation",
        description: "Account Payable",
        url: "https://product-url.com/"
    },
    {
        title: "P&L Reporting",
        description: "Flux Dashboard",
        url: "https://product-url.com/"
    },
    {
        title: "Working Capital Optimisation",
        description: "Account Payable",
        url: "https://product-url.com/"
    },
]