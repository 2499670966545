import { secondAccordion } from "./AccordionData";
import AccordionFile from "./AccordionFile";
import gif1 from "../../assets/img/Aadi/process flow 1 4.gif";
import { useMediaQuery, useTheme } from "@mui/material/";
import mobileGif from "../../assets/img/Aadi/mobile-aadi-infographic-section6.gif"

const Section4 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <div id="section4" className="aadi-section4 scroll-section">
        <div className="main">
          <div className="heading">AI for Structured Data</div>
          <div className="text">
            Coordinate and orchestrate the interactions between specialised
            agents for tasks like information retrieval, natural language
            understanding, data extractor, summarizer, visualisation agents to
            provide comprehensive and coherent responses to user queries
          </div>
          <div className="flex-container">
            <div className="right">
              {isMobile ? (
                <img src={mobileGif} alt="Aays_Finance Co-Pilot" />
              ) : (
                <img src={gif1} alt="Aays_Finance Co-Pilot" />
              )}
            </div>
            <div className="left">
              <div className="accordian-file">
                <AccordionFile data={secondAccordion} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section4;
