import React from "react";
import { useMediaQuery } from "@mui/material";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import "../pageLayout/Newsroom/newsroom.scss";
import Section1 from "../pageLayout/Newsroom/Section1";
import Section2 from "../pageLayout/Newsroom/Section2";
import Section4 from "../pageLayout/Newsroom/Section4";
import Section5 from "../pageLayout/Newsroom/Section5";
import Footer from "../nav/Footer";
import Navbar from "../nav/NavBar";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import CustomerSuccessSection from '../pageLayout/AboutPageLayout/Section5';
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import mobileBannerImage from "../assets/img/newsroom-banner-right.png"
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import BottomBanner from "../reuseLayout/BottomBanner";

const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + 700);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y - 700);
};

function Newsroom() {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  return (
    <div id="homePage" className="newsroomPageCss">
      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Stay Updated with Our "
          text2="Latest News and Announcements"
          subtext1="Press Releases, Company Updates"
          subtext2="and Industry News"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Stay Updated with Our "
          text2="Latest News and Announcements"
          subtext1="Press Releases, Company Updates"
          subtext2="and Industry News"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Stay Updated with Our "
          text2="Latest News and Announcements"
          subtext1="Press Releases, Company Updates"
          subtext2="and Industry News"
          picture={mobileBannerImage}
        />
      )}
      <MiddleBanner text="Keeping you informed on updates, industry trends, and noteworthy achievements, providing an insightful glimpse into our dynamic world" />
      <Section1 />
      <Section5 />

      <Section2 />
      <Section4 />
      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <CustomerSuccessSection
            data={CustomerSuccessStoriesData}
            tag="newsroom"
            show="true"
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="newsroom"
            show="true"
          />
        )}
      </div>
      <BottomBanner contentChange={true} />
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default Newsroom;
