export const freq1 = [
    {
        id: 1,
        title: " How can organizations leverage artificial intelligence (AI) to improve decision-making processes?",
        description:
          "Organizations can leverage AI to enhance decision-making by using data-driven insights, automating routine tasks, and predicting future trends. AI-driven systems analyze voluminous amounts of data to decode patterns and trends that may not be immediately apparent, enabling more accurate and timely decisions.",
      },
      {
        id: 2,
        title: "How do ML and AI help businesses use their enterprise data effectively? ",
        description:
          "ML and AI help businesses use their enterprise data effectively by identifying actionable insights, optimizing processes, and predicting outcomes. These technologies allow enterprises to make data-driven decisions, improve efficiency, and gain a competitive edge. ",
      },
      {
        id: 5,
        title: "How is AI used in business decision-making?",
        description:
          "AI is used in business decision-making to analyze data, predict outcomes, and automate decisions. It helps firms make more informed decisions by providing real-time insights, optimizing operations, and reducing human biases.",
      },
      {
        id: 4,
        title: "What examples are there of AI being used to support decision-making?",
        description:
          "Examples of AI being used as decision-making support include predictive analytics for market trends, AI-driven risk assessments, and forecasting etc. AI is also used in customer relationship management (CRM) to provide personalized recommendations and improve customer satisfaction.",
      },
];
export const freq2 = [

  {
    id: 3,
    title: "What is the role of big data and AI artificial intelligence in operations decision-making?",
    description:
      "Big data and AI play a crucial role in operations decision-making by providing deep analytical insights, automating processes, and optimizing supply chains. These technologies empower businesses to make informed decisions that improve efficiency and reduce costs. ",
  },
      {
        id: 6,
        title: "What are the applications of artificial intelligence in decision-making?",
        description:
          "AI applications in decision-making include risk assessment, predictive analytics, customer segmentation, and supply chain optimization. AI can also automate routine tasks, freeing human resources for more strategic activities.",
      },
      {
        id: 7,
        title: "What are the benefits of AI decision-making?",
        description:
          "The benefits of AI decision-making include improved accuracy, efficiency, and speed. AI enables businesses to make data-driven decisions, cut op-ex, and respond quickly to market changes.",
      },

];
export const freq18 = [
  {
    id: 12,
    title: "What is the power of artificial intelligence in personalized marketing?",
    description: "The strength of artificial intelligence in personalized marketing stems from its capacity to swiftly and accurately analyze large volumes of data. AI can uncover patterns and trends in customer behavior that would be challenging for humans to detect, allowing businesses to deliver highly targeted and relevant marketing messages. This results in more meaningful customer interactions, higher engagement, and improved conversion rates, ultimately driving business growth.",
  },
  {
   id: 13,
   title: " How does AI help with personalization?",
   description: "AI personalizes experiences by analyzing customer data to grasp their preferences, behaviors, and needs. Utilizing machine learning algorithms, AI can predict the products and services a buyer is more likely to be interested in and offer real-time tailored recommendations. AI also enables businesses to automate and scale their personalization efforts, ensuring that every customer receives a tailored experience, regardless of the size of the customer base."
  },
  {
    id: 15,
    title: "What is the importance of personalization in modern marketing?",
    description: "Personalization is crucial in modern marketing because it allows business firms to connect with their customers more deeply. In a world where consumers are bombarded with generic marketing messages, personalized experiences stand out and create a lasting impression. Personalization increases customer satisfaction, loyalty, and engagement, leading to higher conversion rates and improved ROI. In today's competitive market, businesses that fail to deliver personalized experiences risk losing customers to competitors who do.",
  },


]
export const freq19 =[
   {
   id: 16,
   title: "What is the impact of personalization in marketing?",
   description: "The impact of personalization in marketing is significant, as it directly influences customer behavior and business outcomes. Personalized marketing messages are more likely to connect with customers, resulting in higher engagement, increased sales, and better customer retention. Personalization enables businesses to enhance their marketing strategies by targeting the right messages to the right customers at the optimal time, which results in more efficient marketing resource use and a better return on investment (ROI)."
   },
   {
    id: 17,
    title: "What is the most significant advantage of personalization?",
    description: "The primary advantage of personalization is its ability to craft impactful and relevant customer experiences. By customizing content and offers to align with individual preferences and needs, business firms can forge stronger connections with their customers. This approach fosters greater customer satisfaction, loyalty, and advocacy—essential for sustained success. Furthermore, personalization enhances marketing campaign effectiveness, resulting in improved conversion rates and increased revenue.",
   }

]