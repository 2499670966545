import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/vendorPayment-caseStudy.png"
const storyData = {
  title:
    "Leveraging AI-based predictive models to optimize Vendor Payments for a multi-billion-dollar consumer goods company",
  content:
    "A global consumer goods company partnered with Aays to enhance their vendor payment tool with AI algorithms. This was done with the objective of reducing early payments and inconsistent payment terms. Predictive models were used to analyse historical data and identify patterns that resulted in around $200 million of cash optimization for the client.",
  subTitle1: "$220 Mn",
  subText1: "cash optimization done through reduced early payments and identification of inconsistent PO and vendor payment terms",
  subTitle2: "",
  subText2: "",
  imageData: picture
};

function Vendor_Payments() {

  useEffect(() => {

 document.title = "AI-led Vendor Payments Solutions | Case Study";
 document.getElementsByTagName("META")[3].content =
   "Explore how Aays used AI algorithms to optimize vendor payments, reducing early payments and inconsistencies, driving $200M cash optimization through analytics. ";

  })

  return (
    <div className="backround-black">
      <Helmet>
        <meta
          name = "keywords"
           content="Vendor payments optimization, cash
          optimization, early payments reduction, payment terms analysis"
        />
      </Helmet>

      <div>
        <Indicusstomersuccessstories data={storyData} />
      </div>

      {/* <Resources /> */}
      <Footer />
    </div>
  );
}

export default Vendor_Payments;
