import React from 'react';
import { AccoladesData } from '../HomePageLayout/AccoladesData';
import AccoladesBox from "../HomePageLayout/AccoladesBox";
import "../HomePageLayout/homeNewChanges.scss";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { useMediaQuery } from "@mui/material";
 

const responsives = {
    desktop: {
        breakpoint: { max: 3000, min:1242 },
        items: 4,
       
    },
    smallDesktop: {
        breakpoint: { max: 1242, min: 1000 },
        items: 3,
       
    },
    tablet: {
        breakpoint: { max: 1000, min: 650 },
        items: 2,
       
    },
    mobile: {
        breakpoint: { max: 650, min: 0 },
        items: 1,
       
    },
};

const Sectionnew = () => {

  const isMobile = useMediaQuery("(max-width: 1000px)");
    const accoladesWrapperStyle = {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '7px',
        
    };

    const imageContainerStyle = {
        display: 'flex',
        justifyContent: 'center', 
       
        alignItems: 'center', 
       // width: '100%', 
       width: '290px',
       
        height: '210px', //200
        border: '1px solid #181818',
       
        overflow: 'hidden',
        backgroundColor: '#181818', 
    }

    const imageStyle = {
       maxWidth: '100%',
        maxHeight: '100%', 
        objectFit: 'cover', 
        padding: '10px',
    };

    const textStyle = {
        marginTop: '10px',
        fontSize: '12px',
        textAlign:'center',
    };

    const mainStyle = {
        marginTop: '28px',
        backgroundColor: 'transparent',
        padding: '0',
        width: '100%', 
        position: 'relative', 
        left: '50%',
        transform: 'translateX(-50%)',
    };

    const headingStyle = {
        fontWeight: 700,
        textAlign: 'center',
        margin: '0 auto',
        width: '100%', 
        color: '#fff',
        fontSize: '30px',
        paddingBottom: '45px'
       
        
    };
    const boxHeadingStyle = {
        fontSize: '12px'
    }


    return (
        <>
      <div id="accolades">
        <div className="main">
          <div className="heading-main">Accolades and Recognitions</div>


            <Carousel
              responsive={responsives}
              dotListClass="custom-dot-list-styles"
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={1500}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
              showDots={isMobile ? false : true}
            >
              {AccoladesData.map((data) => (
                <AccoladesBox
                  key={data.id}
                  title={data.heading}
                  description={data.description}
                  image={data.image}
                  className='main'
                />
              ))}
            </Carousel>
          
        </div>
      </div>
    </>
  );
};
export default Sectionnew;
      

