import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

function PositionReuse(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        width: "100%",
        paddingLeft: isMobile ? 0 : "20%",
      }}
    >
      <div>
        <span
        className="title"
          style={{
            marginBottom: "10px",
            color: "#bb1ccc",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {props.title}
        </span>
        <div
          style={{
            minHeight: "75px",
          }}
        >
          <div>
            <span
              className="position"
              style={{
                marginBottom: "10px",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: '23px'
              }}
            >
              Position : {props.positionDescription}
            </span>
          </div>
          <div>
            <span
              className="experience"
              style={{
                marginBottom: "10px",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Experience : {props.experience} years
            </span>
          </div>
        </div>
        <div
        className="content"
          style={{
            fontSize: "16px",
            width: "100%",
            minHeight: "80px",
            marginTop: '4px'
          }}
        >
          <i>{props.content}</i>
        </div>
        <a href={props.link}>
          <div style={{}}>
            <button
              style={{
                marginTop: "10px",
                fontSize: "16px",
                backgroundColor: "#bb1ccc",
                padding: "8px 10px",
                outline: "none",
                border: "none",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Apply Now
            </button>
          </div>
        </a>
      </div>
    </div>
  );
}

export default PositionReuse;
