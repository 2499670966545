import React from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import company1 from "../../assets/img/company1.png";
import company2 from "../../assets/img/company2.jpg";
import company3 from "../../assets/img/company3.jpg";
import company4 from "../../assets/img/Gen AI.square.jpg";
import dataEngineering from "../../assets/img/Data Engineering.square.jpg";
import { useMediaQuery, useTheme } from "@mui/material/";
import bestFirm from "../../assets/img/Workplace Leader.square.1.jpg";
import AaysFinancial from "../../assets/img/Aays_Financial Times_Statista .Square.jpg"

// import { flexbox } from "@mui/system";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1345 },
    items: 4,
  },
  superdesktop: {
    breakpoint: { max: 1345, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};


function Section5() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      id="section5-blog-success-story"
      className="blog-success-section2 scroll-section"
    >
      <div className="customer-section2-body margin-box">
        <div
          style={{ paddingBottom: "4rem" }}
          className="success-story-page-section-2-Header-text"
        >
          Company Updates
        </div>
        <div className="section2-box ">
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            className="new-cls-blogs"
          > */}

          <Carousel
            // className="customer-success-main"

            responsive={responsive}
            dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
            arrows={false}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            infinite={true}
            slidesToSlide={1}
            swipeable={true}
            draggable={false}
            // showDots={isMobile? false : true}
          >
            <CustomerSectionBox
              picture={AaysFinancial}
              title1="Aays Listed in 500 High Growth Companies Asia Pacific 2024 by Financial Times and Statista "
              date="July 4, 2024"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/aays-high-growth-companies-asia-pacific-2024"
            />
            <CustomerSectionBox
              picture={dataEngineering}
              title1="Aays Recognized as one of the Leaders in Data Engineering PeMa Quadrant "
              date="May 27, 2024"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/aim-ranks-aays-a-leader-in-data-engineering-service-providers"
            />
            <CustomerSectionBox
              picture={bestFirm}
              title1="Recognised as a Workspace Leaders in the List of 50 Best Firms for Data Scientists to Work for"
              date="May 26, 2024"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/50-best-firms-for-data-scientists-2024"
            />
            <CustomerSectionBox
              picture={company4}
              title1="Aays Recognized as 'Seasoned Vendor' in Gen AI Report by AIM Research "
              date="Aug 7, 2023"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/top-gen-ai-service-providers-aim-report-2023"
            />
            <CustomerSectionBox
              picture={company1}
              title1="Renewing our partnership with Microsoft: From Gold to Azure Solutions Partner"
              date="Aug 6, 2023"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/microsoft-solutions-partner-azure"
            />
            <CustomerSectionBox
              picture={company2}
              title1="Aays Analytics forays into APAC region; kick-starts operations in Singapore"
              date="September 19, 2022"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/aays-in-singapore"
            />
          {/*  <CustomerSectionBox
              picture={company3}
              title1="Aays Analytics is now a Microsoft gold partner"
              date="Jun 22, 2021 "
              class="content-box-section2a"
              read="2 min read"
              redirectURL="blogs/microsoft-gold-partners"
            /> */}
          </Carousel>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Section5;
