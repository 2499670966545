import image1 from "../../../assets/img/genai/8-01.png";
import image2 from "../../../assets/img/genai/6-01.png";
import image3 from "../../../assets/img/genai/3-01.png";
import image4 from "../../../assets/img/genai/2-01.png";
import image5 from "../../../assets/img/genai/5-01.png";
import image6 from "../../../assets/img/genai/1-01.png";
import image7 from "../../../assets/img/genai/7-01.png";
import image8 from "../../../assets/img/genai/4-01.png";
import image9 from "../../../assets/img/genai/9-01.png";

// data-engineering blog images
import engImage1 from "../../../assets/img/genai/technology (2).png";
import engImage2 from "../../../assets/img/genai/technology (3).png";
import engImage3 from "../../../assets/img/genai/secure-data.png";
import engImage4 from "../../../assets/img/genai/data-science (1).png";

export const KeyPointGenAIData = [
  {
    id: 1,
    title: "Integration with Broader Services",
    description:
      "The vendor's approach to integrating genAI as a component of their broader consulting and analytics services allows them to tap into a wider client base that seeks holistic solutions. This strategy positions them favorably for market penetration as they can cater to clients looking for comprehensive AI-driven consulting.",
    image: image1,
  },
  {
    id: 2,
    title: "Diverse Skill Development",
    description:
      "The vendor's efforts in hiring dedicated roles, reskilling existing associates, and establishing a genAI proposition team indicate a proactive approach to building a skilled workforce capable of effectively promoting and delivering genAI solutions to clients.",
    image: image2,
  },
  {
    id: 3,
    title: "Effective Handling of Complex Use Cases",
    description:
      "The vendor's ability to build genAI capabilities for critical functional areas like finance, supply chain, etc. addressing complex needs of larger enterprise clients, demonstrates a high level of technical maturity.",
    image: image3,
  },
  {
    id: 4,
    title: "Structured Data Integration",
    description:
      "The commitment to seamlessly working with diverse data formats positions them as a reliable partner for clients with varied data requirements.",
    image: image4,
  },
  {
    id: 5,
    title: "Mitigating Challenges and Bias",
    description:
      "The vendor's approach to treating GenAI as a reasoning engine rather than a fact engine, breaking down complex tasks into smaller components, and implementing multi-layer filtering to reduce biases and hallucination issues demonstrates a deep understanding of the technology's limitations and a mature approach to managing these challenges.",
    image: image5,
  },
  {
    id: 6,
    title: "Cost-Effective Infrastructure Management",
    description:
      "The vendor's approach to treating GenAI as a reasoning engine rather than a fact engine, breaking down complex tasks into smaller components, and implementing multi-layer filtering to reduce biases and hallucination issues demonstrates a deep understanding of the technology's limitations and a mature approach to managing these challenges.",
    image: image6,
  },
  {
    id: 7,
    title: "Transition from POCs to Scaling Engagements",
    description:
      "The impressive track record of transitioning POCs into scaling engagements indicates their ability to showcase the value of genAI to clients, which is a key driver for market penetration as satisfied clients are likely to expand their usage of the technology",
    image: image7,
  },
  {
    id: 8,
    title: "Significant R&D Investment",
    description:
      "This demonstrates a strong commitment to advancing genAI capabilities. This investment can result in the development of cutting-edge solutions that attract a broader customer base.",
    image: image8,
  },
  {
    id: 9,
    title: "Strategic Partnerships",
    description:
      "Strong collaborations with industry giants and partnerships with AI research companies provide access to a wide network of potential clients and enhance the vendor's credibility, facilitating market penetration.",
    image: image9,
  },
];

export const KeyPointDataEngineeringData = [
  {
    id: 1,
    title: "Focus on Technological Advancement ",
    description:
      "The company reduced its storage needs from 30 TB to 12 TB by using advanced deduplication techniques and optimizing storage formats, which lowered costs and improved data processing speeds by removing redundant and obsolete data through effective data lifecycle management policies. ",
    image: engImage1,
  },
  {
    id: 2,
    title: "End-to-End Data Engineering Pipeline Expertise ",
    description:
      "The company showcases its proficiency in data warehousing, ETL (Extract, Transform, Load), and data pipeline technologies, highlighting their ability to handle large-scale, complex data architectures that are secure and scalable. ",
    image: engImage2,
  },
  {
    id: 3,
    title: "Scalability and Security for Data Engineering Infrastructure ",
    description:
      "The company understands the importance of scalability and security in data engineering. When providing data engineering services, they can design and implement scalable infrastructure that can handle increasing data volumes and model complexity. Additionally, they prioritize security measures to ensure the protection of sensitive data and models used in the data engineering lifecycle. ",
    image: engImage3,
  },
  {
    id: 4,
    title: "Building Talented Data Engineering Workforce ",
    description:
      "The company actively invests in building a skilled data engineering team. This includes emphasis on continuous learning and development for data engineers, supported by various programs like mentorship, hands-on project work, and upskilling in the latest technologies. ",
    image: engImage4,
  },
];
