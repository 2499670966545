import Footer from "../nav/Footer";
import Navbar from "../nav/NavBar";
import Product from "./Product";
import "./portal.scss";

const Portal = () => {

    return (
        <div id="homePage" className="portal-main" >
            <Navbar />
            {/* other components will start from here  */}
            <div className="portal-body"  >
                <Product />
            </div>
            <Footer />

        </div>
    )
}

export default Portal;