import React from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import "react-multi-carousel/lib/styles.css";
import company1 from "../../assets/img/company1.png";
import company2 from "../../assets/img/company2.jpg";
import company3 from "../../assets/img/company3.jpg";

function Section5() {
  return (
    <div
      id="newsroom-section5"
      className="blog-success-section2"
     
    >
      <div className="newsroom-section2-body margin-box">
        <div className="success-story-page-section-2-Header-text">
          Interviews
        </div>
        <div className="section2-box ">
          <div
            style={{
              display: "flex",
              gridGap:"30px",
              justifyContent:"center",
              flexWrap: "wrap",
              position: "relative"
            }}
          >
            <CustomerSectionBox
              picture={company1}
              title1="Discussion between Techgraph and Anil Kumar on AI, ML, and Data Science
"
              date="Aug 6, 2023"
              class="content-box-section2a"
              read="2 min read"
              color="bg-black"
              redirectURL='https://techgraph.co/interviews/big-data-and-ai-will-be-crucial-in-analytics-network-anil-kumar-vp-aays-analytics/'

            />
            <CustomerSectionBox
              picture={company2}
              title1="Chai Pe Charcha with BrandWagon"
              date="September 19, 2022"
              class="content-box-section2a"
              read="2 min read"
              color="bg-black"
              redirectURL='https://www.financialexpress.com/business/brandwagon-chai-pe-charcha-with-brandwagon-12-2810542/'

            />
            <CustomerSectionBox
              picture={company3}
              title1="Aays Analytics on a mission to bridge the data inventory gap, particularly in the finance realm: Analytics Insight Magazine"
              date="Jun 22, 2021 "
              class="content-box-section2a"
              read="2 min read"
              color="bg-black"
              redirectURL='https://www.analyticsinsight.net/exclusive-interview-with-anshuman-bhar-ceo-founder-aays-analytics/'

            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section5;
