import React, {useState} from "react";
import "../pageLayout/DataEngineering/Dataeng.scss";
import ApplyJobs from "../pages/OpenPositions/ApplyJobs";

const HeaderSection = (props) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="header-section">
      <div
        className="layoutGrid"
        style={{
          background: `url(${props.picture})`,
          width: "100%",
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "grid",
        }}
      >
        {/* <div className="back-button">
          {data.map((ele, index) => (
            <div style={{display: 'flex'}}>
            <a href={ele.url}className="content" key={index}>
              {ele.text}
            </a>
            {index !== data.length - 1 && <div style={{color: 'white'}}>/</div>}
            </div>
          ))}
        </div> */}

        <div className="header_section_1">
          <div>
            <p className="firstText">
              {props.text1 && (
                <>
                  {props.text1} <br />
                </>
              )}
              {props.text2 && (
                <>
                  {props.text2} <br />
                </>
              )}
              {props.text3 && (
                <>
                  {props.text3} <br />{" "}
                </>
              )}
              {props.text4 && <>{props.text4}</>}
            </p>

            {
             props.subtext1  && (
                 <p className="secondText">
              {props.subtext1} <br /> {props.subtext2}{" "}
            </p>
               )
           }

            {props.button && (
              <div
                style={{
                  padding: "2rem 0"
                }}
              >
                <button style={{
                  padding: "10px 8px",
                  outline: "none",
                  border: "none",
                  backgroundColor: "#bb1ccc",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                onClick={() => setOpenModal(true)} >Apply Now</button>
              </div>
            )}
          </div>
            
          <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position={props.text1}/>

        </div>
      </div>
    </div>
  );
};

export default HeaderSection;

