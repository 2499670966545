import React from "react";

function EmployeeFeedback(props) {
  return (
    <div
      style={{
        padding: "0 20px",
      }}
    >
      <div>
        <span>
          <center>
            <div
              style={{
                height: "80px",
                width: "80px",
                paddingBottom: "2rem",
                borderRadius: "50%",
                border: "5px solid #bb1ccc",
                backgroundImage: `url(${props.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                marginBottom: "0.5rem",
              }}
              className="pic"
            ></div>

            <i>{props.content}</i>

            <div
              style={{
                marginTop: "1.5rem",
              }}
              className="designation"
            >
              <span>
                <b> {props.name}</b>
              </span>
              <br />

              <span>{props.designation}</span>
            </div>
          </center>
        </span>
      </div>
    </div>
  );
}

export default EmployeeFeedback;
