import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/ind_blogs_Architectural_pattern.jpg";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
function BusinessAnalyticsArchitecture() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

  useEffect(() => {
    
       document.title = "Business Analytics Architecture";
       document.getElementsByTagName("META")[3].content =
         "Setting up your Business Analytics Architecture in Azure Cloud? Create proper infrastructure for storing the data available in your organization. ";
  })
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];

  return (
    <div id="homePage">
      <Helmet>
        <meta name="keywords" content="data architecture" />
      </Helmet>

      <Navbar bg="black" />
      <GoogleAnalytics/>

      <div className="GenAi-body">
        <div className="margin-blogMore">
          <div className="GenAi-main">
          <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
            <h1 className="main-heading">
              {" "}
              Architectural Patterns for Analytics Journey Leveraging Azure
              Cloud Tech-Stack
            </h1>

            <img
              className="blogImg"
              style={{ filter: "grayscale(1)" }}
              src={GenAiBanner}
              alt="blog1"
            />

            <div className="imgContent">
              <p className="heading-content">
                We are excited to embark on this renewed journey with Microsoft,
                as we continue to lead the way in excellence and innovation.
              </p>

              <h1 className="main-heading">
                {" "}
                Importance of Data Infrastructure in an Organization
              </h1>

              <p className="heading-content">
                In today’s world any organization which is not utilizing its
                data for gaining insights will fall behind its competitors. Data
                has become an important part of the working of an organization
                be it for monitoring, analyzing, or predicting requirements.
              </p>

              <p className="heading-content">
                Every organization has a certain type of data requirement. Some
                require historical data; some require periodic data and others
                require live data. To ensure your current and future data
                requirements are met it is advisable to create proper
                infrastructure for storing the data available in the
                organization.
              </p>

              <p className="heading-content">
                The stages which an organization goes through can be broadly
                classified as:
              </p>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>{" "}
                Low-Tier (Very low data volumes/PoC).
              </p>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>{" "}
                Mid-Tier (Small to medium data volume).
              </p>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>{" "}
                Advanced-Tier (Large data volume with data science
                requirements).
              </p>

              <p className="heading-content">
                Cloud Technologies for Corporate Analytics Implementation
              </p>

              <p className="heading-content">
                Before discussing the technologies available for each of the
                above classifications, let us have a quick overview of cloud
                technologies.
              </p>

              <p className="heading-content">
                Backend cloud technologies are technologies available in the
                cloud for storing and processing data in the cloud. Just like a
                basic computer system, computation in cloud also needs a CPU, a
                RAM and Storage. In cloud we have this advantage where we can
                scale-up or scale-down or even shut down the resources as per
                our needs.
              </p>

              <p className="heading-content">
                Different clouds have different technologies which can be used
                for processing data. Technologies differ depending upon the
                cloud used, reporting needs, frequency of reporting,
                pre-aggregation on the dataset, etc.
              </p>

              <p className="heading-content">
                Though all the clouds have technologies to handle all kinds and
                volumes of data, we will be looking into the{" "}
                <a
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    display: "inline-block",
                    color: "#E67D1C",
                  }}
                  href="../azure-analytics-and-power-bi-services.html"
                >
                  Azure Cloud
                </a>{" "}
                for our discussion.
              </p>

              <p className="heading-content">
                Analytic architectures available in the Azure Cloud
              </p>

              <p className="heading-content">
                Now that we know what backend technologies are, let us discuss
                some of the options available when setting up your Business
                Analytics Architecture in Azure Cloud depending on the volume of
                data available.
              </p>

              <p
                style={{
                  display: "flex",
                  gridGap: "10px",
                  alignItems: "baseline",
                }}
                className="heading-content"
              >
                <i>
                  <b style={{color:'black'}}>1)</b>{" "}
                </i>
                <p className="heading-content">
                  <b style={{color:'black'}}>Low-Tier:</b> Low-tier is for exceedingly small
                  organizations with data volumes in a few MBs. These
                  technologies can be used for Proof-of-Concept analytics in
                  larger organizations. These technologies are very cost
                  effective, and the turnaround time is very small.
                </p>
              </p>
              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 90%)",
                }}
                className="heading-content"
              >
                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                    paddingTop: "0rem",
                  }}
                  className="heading-content"
                >
                  <i>
                    <b style={{color:'black'}}>a)</b>{" "}
                  </i>
                  <p className="heading-content">
                    <b style={{color:'black'}}> Microsoft Excel:</b> The easiest option for getting your
                    dashboards is to use Microsoft excel/csv files as an input
                    to Power BI. The files can be stored in a Data Lake or an
                    FTP server and can be refreshed by adding new data to the
                    location. This option is suggested only when the data volume
                    is low, and the computation does not require complicated
                    measures/columns. Once the data volume increases, this
                    architecture cannot sustain the increased load as it cannot
                    be scaled up. This architecture is the easiest to develop as
                    well because it does not require expertise in complicated
                    backend technologies.
                  </p>
                </p>
              </p>

              <p
                style={{
                  display: "flex",
                  gridGap: "10px",
                  alignItems: "baseline",
                  paddingTop: "0rem",
                }}
                className="heading-content"
              >
                <i>
                  <b style={{color:'black'}}>2)</b>{" "}
                </i>
                <p className="heading-content">
                  <b style={{color:'black'}}>Mid-Tier: </b> Mid-tier technologies work best when data
                  volumes are from 10 to 15 GBs. They are comparatively
                  inexpensive, and the development costs are moderate.
                </p>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 90%)",
                  gridGap: "20px",
                  paddingTop: "0rem",
                }}
                className="heading-content"
              >
                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i>
                    <b style={{color:'black'}}>a)</b>{" "}
                  </i>
                  <span>
                    <p className="heading-content">
                      {" "}
                      Azure Analysis Services/SQL Server Analysis Services
                      (SSAS):{" "}
                    </p>
                  </span>
                </p>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 80%)",
                  gridGap: "20px",
                  paddingTop: "1rem",
                }}
                className="heading-content"
              >
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  >
                    {" "}
                  </i>
                  <p className="heading-content">
                    SSAS is the traditional service provided by Microsoft for
                    adding computation capacity to Power BI reports. This
                    service has the advantage that all the calculated columns
                    and measures could be moved from Power BI to an SSAS model.
                    The server created to support the backend can store multiple
                    models and can be scaled up or down. This is the “go-to”
                    option for adding computation capacity for{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      style={{ width: "100%", textDecoration: "none" }}
                      href="https://blogs.perficient.com/2021/01/05/making-meaningful-data-reports-with-microsoft-power-bi/"
                    >
                      Power BI Reports
                    </a>{" "}
                    .
                  </p>
                </span>
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    Traditional SSAS server can be scaled up from one tier to
                    another and it does not automatically scale up/down
                    depending on the requirements. To address this, Microsoft
                    has launched the Azure Analysis Service which gives more
                    control over the server size and hence optimizes cost of
                    operation depending upon the load.
                  </p>
                </span>
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    Once the model is created, we can connect it with a direct
                    query to Power BI report. Power BI report created using
                    direct query does not store any report on the Power BI
                    server. All the data is computed in the backend server and
                    then displayed in the Power BI report. If we create an
                    import connection in Power BI, then all the advantages of
                    getting the SSAS/Azure AS server would be gone.
                  </p>
                </span>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 90%)",
                  gridGap: "20px",
                  paddingTop: "2rem",
                }}
                className="heading-content"
              >
                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i>
                    <b style={{color:'black'}}>b)</b>{" "}
                  </i>
                  <span>
                    <b style={{color:'black'}}>SQL Server: </b>
                  </span>
                </p>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 80%)",
                  gridGap: "20px",
                }}
                className="heading-content"
              >
                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                  className="heading-content"
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    SQL Servers are the most used databases when it comes to{" "}
                    <b style={{color:'black'}}>storing corporate data</b>. For starting your journey in
                    BI, these can be the steppingstones for creating dashboards
                    directly from the SQL databases. Power BI supports both
                    direct and import query modes when connecting with SQL
                    servers. Moreover, Power BI also gives an option of writing
                    SQL queries and sending them to SQL server. This saves the
                    need for creating views/stored procedures in SQL.
                  </p>
                </p>
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    This works well because complicated queries can be stored as
                    tables in SQL Server and then used directly for reporting.
                    Also, data can be aggregated for visualization in the
                    backend, reducing the computation required in Power BI.
                  </p>
                </span>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 90%)",
                  gridGap: "20px",
                  paddingTop: "2rem",
                }}
                className="heading-content"
              >
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i>
                    <b style={{color:'black'}}>c)</b>{" "}
                  </i>
                  <span>
                    <b style={{color:'black'}}>Azure Function with Storage Account: </b>
                  </span>
                </span>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 80%)",
                  gridGap: "20px",
                  paddingTop: "1rem",
                }}
                className="heading-content"
              >
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    All the technologies we have seen above support an elevated
                    level of transformation and aggregation. In case your
                    requirement is to run complicated transformations or machine
                    learning models, then you would need the{" "}
                    <b>
                      flexibility of a programming language for your analysis
                    </b>
                    .
                  </p>
                </span>

                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    Azure Function becomes the answer to your needs in this case
                    as it gives a variety of languages to code your
                    requirements. As an Azure Function has no built-in memory,
                    the output of the analysis is stored in a Storage Account
                    (ADLS) available in Azure. The Power BI dashboard can then
                    be created using the processed data from Storage account. We
                    can also use an SQL server in this case if the data volume
                    is large.
                  </p>
                </span>

                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    Azure function supports programming languages like Node.js,
                    python, PowerShell, .NET, and Java.
                  </p>
                </span>
              </p>

              <p
                style={{
                  display: "flex",
                  gridGap: "10px",
                  alignItems: "baseline",
                  paddingTop: "0rem",
                }}
                className="heading-content"
              >
                <i>
                  <b style={{color:'black'}}>3)</b>{" "}
                </i>
                <p className="heading-content">
                  <b style={{color:'black'}}> Advanced-Tier: </b> Advanced-Tier technologies are for
                  very large data volumes and AI/ML requirements using large
                  datasets up to 100GBs. These technologies are expensive,
                  highly scalable, and immensely powerful. They can even be used
                  for live streaming data as well.
                </p>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 90%)",
                  gridGap: "20px",
                  paddingTop: "2rem",
                }}
                className="heading-content"
              >
                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                  className="heading-content"
                >
                  <i>
                    <b style={{color:'black'}}>a)</b>{" "}
                  </i>
                  <p className="heading-content">
                    <b style={{color:'black'}}> Power BI Premium:</b> Power BI Premium is the latest
                    cloud-based technology which can be used{" "}
                    <b style={{color:'black'}}>
                      for giving more computation power for handling large
                      datasets in Power BI Service
                    </b>
                    . Power BI Premium costs more compared to SSAS server but
                    there is no extra development cost when it comes to moving
                    the model to SSAS server. It uses dedicated Power BI
                    embedded capacity for storage and computing. An embedded
                    capacity can be shared by multiple workspaces.
                  </p>
                </p>

                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                  className="heading-content"
                >
                  <i>
                    <b>b)</b>{" "}
                  </i>
                  <p className="heading-content">
                    <b style={{color:'black'}}>SQL Server Hyperscale Tier:</b> In case the General
                    Purpose/Standard or Premium tier is insufficient for your
                    data needs, Microsoft has come up with a hyper-scale tier
                    that is based on the vCore purchasing model. This enables
                    your <b style={{color:'black'}}>database to scale up to 100TB</b>. It also gives an
                    option to <b style={{color:'black'}}>add more computing capacity</b> depending on
                    the processing requirements. The option for scaling up or
                    down is available and optimizes the cost according to your
                    computing requirements.
                  </p>
                </p>

                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                  className="heading-content"
                >
                  <i>
                    <b style={{color:'black'}}>c)</b>{" "}
                  </i>
                  <span>
                    <b style={{color:'black'}}>Databricks with Storage Account: </b>
                  </span>
                </p>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 80%)",
                  gridGap: "20px",
                  paddingTop: "1rem",
                }}
                className="heading-content"
              >
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    <a
                      target="_blank"
                      style={{ width: "100%", textDecoration: "none",color:'black' }}
                      href="../databricks.html"
                    >
                      Databricks
                    </a>{" "}
                    is a managed PaaS (platform as a service) based on Apache
                    Spark. It is available on major cloud platforms. Databricks
                    supports
                    <b>parallel processing</b> . It is very well integrated with
                    Azure Storage Accounts which enables it to process a large
                    dataset very quickly and efficiently. It is user-friendly as
                    it allows users to code in SparkSQL, Scala, PySpark and R.
                    These are the major languages used by data engineers/data
                    analysts all over the world. It also supports machine
                    learning and artificial intelligence libraries which makes
                    the data science part easy to implement and productionize.
                  </p>
                </span>

                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    Databricks uses Jupyter like notebooks which enables
                    multiple users to collaborate. It has Git/DevOps
                    integration, enabling users to move back to a previous
                    version of the notebook.
                  </p>
                </span>

                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    As Databricks does not have any storage in its servers, data
                    is stored in azure storage accounts after processing.
                    Databricks clusters can be switched-on using Azure Data
                    Factory and shutdown automatically if not in use. This makes
                    it a very cost-effective solution. Also, the same cluster
                    can be used by multiple notebooks.
                  </p>
                </span>

                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    class="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    Once the data is processed and saved in storage account, it
                    can be accessed by Power BI directly or through an SQL
                    Server.
                  </p>
                </span>
              </p>

              <p
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 90%)",
                  gridGap: "20px",
                  paddingTop: "0rem",
                }}
                className="heading-content"
              >
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i>
                    <b style={{color:'black'}}>d)</b>{" "}
                  </i>
                  <p className="heading-content">
                    <b style={{color:'black'}}>Azure Synapse with Storage Account: </b>
                  </p>
                </span>
              </p>

              <span
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "repeat(1, 80%)",
                  gridGap: "20px",
                  paddingTop: "1rem",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    className="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    Azure Synapse is the proprietary Data analytics, Machine
                    Learning and Artificial Intelligence platform developed by
                    Microsoft. It <b style={{color:'black'}}>supports serverless SQL DW pool</b>
                    and Apache spark pool for data warehousing, code free data
                    flow for big data transformation, data integration &
                    orchestration to integrate and operationalize your data and
                    a web-based studio to access all these capabilities.
                  </p>
                </span>

                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    className="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    As it supports a serverless architecture, the cost can be
                    optimized to a minimum. It also supports a variety of coding
                    languages like SQL when using SQL DW, python, Scala, PySpark
                    , C# and SparkSQL when using Apache spark pool.
                  </p>
                </p>

                <p
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    style={{ color: "green" }}
                    className="fa fa-check"
                    aria-hidden="true"
                  ></i>
                  <p className="heading-content">
                    This architecture can use the SQL pool for storing all the
                    data that is generated after processing raw data. It is
                    priced higher than all the technologies discussed above but
                    gives a complete package for your analytic needs.
                  </p>
                </p>
              </span>

              <h1 className="main-heading"> Conclusion</h1>

              <p className="heading-content">
                The technologies discussed above are just a few examples of
                starting your Analytics journey in the Cloud. The technologies
                we have discussed are more inclined towards big data. There are
                other technologies used for analyzing streaming data like Kafka,
                event hub, etc. which will be discussed in subsequent articles.
              </p>

              <p className="heading-content">
                Overall, designing a sustainable and scalable architecture is
                the goal when starting your BI journey as each of the
                technologies has their pros and cons and a cost attached to it.
                There are no standard rules when designing architectures for
                Analytics. Hence, it is advisable to get help from a cloud
                architect when starting your journey as it will ensure that you
                have less trouble when addressing your future needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default BusinessAnalyticsArchitecture;
