import React, {useState} from 'react';
import $ from "jquery";
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
function ContactUs({openModal = false, setOpenModal = () => {}}) {
  const [isloading, setisLoading] = useState(false);
  const[successModal, setSuccessModal] = useState(false);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setisLoading(true);

      var URL = "https://kixepycste.execute-api.us-east-1.amazonaws.com/prod/contactus";
      var name = $("#name-input").val();
      var phone = $("#phone-input").val();
      var email = $("#email-input").val();
      var desg = $("#designation-input").val();
      var message = $("#message-input").val();
      var company = $("#company-input").val();

      var contactUsData = {
        name: name,
        phone: phone,
        email: email,
        designation: desg,
        message: message,
        company: company,
      };

      $.ajax({
        type: "POST",
        url: URL,
        dataType: "json",
        crossDomain: "true",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(contactUsData),
  
        success: function () {
          // clear form and show a success message
          setisLoading(false);
          setSuccessModal(true);
          setTimeout(() => {
            setSuccessModal(false);
          }, 5000);
          document.getElementById("contact-form").reset();
        },
        error: function () {
          setisLoading(false);
          alert("Something went wrong, please try after sometime");
        },
      });
    }

  return (
    <div>
    {openModal && <div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="modal-box">
            <form id="contact-form" onSubmit={handleSubmit}>
                <div className="contact-container">
                    <div>
                        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",alignContent:"center"}}>
                          <div className="contact-heading">Contact Us</div>
                        <div onClick={()=>setOpenModal(false)} style={{cursor: 'pointer'}}><HighlightOffIcon/></div>

                        </div>
                        <p>Please complete all fields.</p>
                    </div>

                {/* email  */}
                <div className="form__field">
                    <input
                      id="email-input"
                      className="input-field-landing font-family"
                      type="email"
                      name="email"
                      placeholder="Email address"
                      required
                    />
                </div>

                  {/* name */}
                  <div className="form__field">
                    <input
                      id="name-input"
                      className="input-field-landing font-family"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </div>

                {/* // designation  */}
                <div className="form__field">
                    <input
                      id="designation-input"
                      className="input-field-landing font-family "
                      type="text"
                      name="designation"
                      placeholder="Designation"
                      required
                    />
                  </div>


                  {/* // phone  */}
                  <div className="form__field">
                    <input
                      id="phone-input"
                      className="input-field-landing font-family"
                      type="tel"
                      name="phone"
                      placeholder="Phone"
                      required
                    />
                  </div>

                  {/* // company  */}
                  <div className="form__field">
                    <input
                      id="company-input"
                      className="input-field-landing font-family "
                      type="text"
                      name="company"
                      placeholder="Company"
                      required
                    />
                  </div>

                  {/* // textarea  */}
                  <div className="form__field">
                    <textarea
                      rows={3}
                      id="message-input"
                      className="input-field-landing font-family "
                      name="message"
                      placeholder="Tell us more about your needs..."
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="submit-btn">
                  <button type="submit" disabled={isloading}>
                    {isloading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span style={{ paddingLeft: "10px" }}>
                          Submitting...
                        </span>
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>

                <div style={{display: 'flex', alignItems: 'center', gap: '1rem', marginTop: '1.5rem'}}>
                  <img src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/trustlogo.jpg" alt="logo1"/>
                  <img src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/nortonlogo.jpg" alt="logo2" />
                </div>
            </form>
        </div>
      </Modal>
    </div>}

    <div>
    {successModal && <Modal
      open={successModal}
      onClose={() => setSuccessModal(false)}
    >
      <div className='modal-box'>
        <div style={{width: '400px', padding: '1rem'}}>
        <div style={{fontSize: '24px', fontWeight: '600', marginBottom: '1rem'}}>Thank you for getting in touch! We appreciate you contacting us.</div>
        <div style={{fontSize: '18px', marginBottom: '2rem'}}>One of our colleagues will get back to you soon. Have a great day!</div>
        <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
          <img src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/trustlogo.jpg" alt="logo1"/>
          <img src="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/nortonlogo.jpg" alt="logo2" />
        </div>
        </div>
      </div>
    </Modal>}
    </div>
  </div>
  )
}

export default ContactUs;
