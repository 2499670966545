import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import aiToolForController from "../../assets/img/AI Tool for Controllers.png";
import workingCapitalAnalytics from "../../assets/img/Working Capital Analytics.png";
import vendorPayment from "../../assets/img/Vendor Payments.png";
import accountReceivable from "../../assets/img/Accounts Receivables.png";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import RightArrow from "./RightArrow";
import LeftArrow from "./LeftArrow";

const items = [
  {
    id: 1,
    title1: "AI Tool For Controllers",
    content:
      "Equipping finance controllers with an AI-powered tool for faster closure of books and outlier detection",
    class: "content-box-section3",
    image: aiToolForController,
    url: "/customer-success-stories/ai-powered-tool-for-finance-controllers",
  },
  {
    id: 2,
    title1: "Working Capital Analytics",
    content:
      "Achieving financial success: How a global CPG company enhanced working capital efficiency with advanced analytics",
    class: "content-box-section3",
    image: workingCapitalAnalytics,
    url: "/customer-success-stories/working-capital-analytics-global-CPG",
  },
  {
    id: 3,
    title1: "Vendor Payments",
    content:
      "Leveraging AI-based predictive models to optimize vendor payments for a multi-billion dollar consumer goods company",
    class: "content-box-section3",
    image: vendorPayment,
    url: "/customer-success-stories/vendor-payments-optimization-case-study",
  },
  {
    id: 4,
    title1: "Account Receivable",
    content:
      "How a global CPG conglomerate strengthened its accounts receivable function by leveraging advanced analytics",
    class: "content-box-section3",
    image: accountReceivable,
    url: "/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics",
  },
];

const Section3 = () => {
  const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad
    ? items.slice(startIndex, startIndex + 2)
    : items.slice(startIndex, startIndex + 3);

  return (
    <div id="caseStudySection3" className="customer-success-section3 section scroll-section">
      <div className="customer-section3-body">
        <div className="success-story-page-section-3-Header-text">
          Commercial & Finance
        </div>
        <div className="content">
          Some highlights of the transformative impact of our AI-led commercial
          and finance solutions, addressing real-world business challenges and
          boosting financial strategy
        </div>
        <div className="success-story-page-section-3-grid">
          {items.length > showLength && (
            <LeftArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
          {visibleItems.map((item) => (
            <div>
              <CustomerSectionBox
                picture={item.image}
                title1={item.title1}
                title2={item.title2}
                content={item?.content}
                class={item.class}
                redirectURL={item.url}
              />
            </div>
          ))}
          {items.length > showLength && (
            <RightArrow
              data={items}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Section3;
