import React from "react";
import BlogAdvanceAnalytics from "../../assets/img/BlogAdvanced.png";
import BlogCompanyUpdates from "../../assets/img/BlogCompany.png";
import InterviewIcon from "../../assets/img/Interview-icon.png";
import $ from "jquery";

$(document).ready(function () {
  $("a").on("click", function (e) {
    // e.preventDefault();

    var target = this.hash,
      $target = $(target);

    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $target.offset().top - 80,
        },
        0,
        "swing",
        function () {}
      );

    return false;
  });
});

const data = [
  {
    id: 1,
    url: "#newsroom-section5",
    Image: InterviewIcon,
    title: "Interviews",
  },
  {
    id: 2,
    url: "#newsroom-section2",
    Image: BlogCompanyUpdates,
    title: "Press Releases",
  },
  {
    id: 3,
    url: "#caseStudySection4",
    Image: BlogAdvanceAnalytics,
    title: "Thought Leadership",
  },
];

function Section1() {
  return (
    <div className="blog-section1-main">
      <div className="customer-section1-body ">
        <div className="section2-new-css">
          {data.map((item) => (
            <a href={item.url}>
              <div className="icon-box">
                <span className="image-div">
                  <img
                    src={item.Image}
                    alt={item.title}
                    className="feature-icon"
                  />
                </span>
                <span className="text">{item.title}</span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Section1;
