import "./keypoints.scss";
import "./keypointsResponsive.scss";

const KeyPoints = ({title, description, image}) => {
  return (
    <>
      <div id="keypoints">
        <div className="top">
          <div className="image">
            <img src={image} alt="icon" />
          </div>
          <div className="heading-line">
                      <div className="heading">{ title }</div>
            <div className="line"></div>
          </div>
        </div>
        <div className="bottom">
          {description}
        </div>
      </div>
    </>
  );
};

export default KeyPoints;
