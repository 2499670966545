import React, { useState } from "react";
import $ from "jquery";
import Alert from "@mui/material/Alert";

function Section1() {
  const [isloading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);
  const [success, setsuccess] = useState(false);

  const submitToAPI = (e) => {
    e.preventDefault();
    setisLoading(true);
    var URL = "https://kixepycste.execute-api.us-east-1.amazonaws.com/prod/contactus";
    var name = $("#name-input").val();
    var phone = $("#phone-input").val();
    var email = $("#email-input").val();
    var desg = $("#designation-input").val();
    var message = $("#message-input").val();
    var company = $("#company-input").val();
  
    var contactUsData = {
      name: name,
      phone: phone,
      email: email,
      designation: desg,
      message: message,
      company: company,
    };

    $.ajax({
      type: "POST",
      url: URL,
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(contactUsData),

      success: function () {
        // clear form and show a success message
        // document.getElementById("SuccessMessageContactus").innerHTML="Thank you for getting in touch! We appreciate you contacting us. We appreciate you contacting us. One of our colleagues will get back to you soon. Have a great day!";
        setisLoading(false);
        setsuccess(true);
        setTimeout(() => {
          setsuccess(false);
        }, 5000);
        document.getElementById("contact-form").reset();
      },
      error: function () {
        // show an error message
        setisLoading(false);
        setisError(true);
        setTimeout(() => {
          setisError(false);
        }, 5000);
        alert("Something went wrong, please try after sometime");
      },
    });
  };

  return (
    <div className="contact-padding">
      <div className="contactUs-section1">
        <h1> Partner with Us for Success</h1>

        <div className="contact-flexContainer">
          <div className="contact-flexLeft">
            {/* <div className="icon">
              <img
                style={{
                  maxWidth: "100%",
                }}
                src={contactUsIcon}
                alt="contact"
              />
            </div> */}

            <div style={{display:"grid",gridGap:'24px',justifyContent:"flex-start"}}>
            <div>
              <div className="title">AI-Powered Solutions </div>

              <div className="content">
                {" "}
                Connect with us to delve into how our AI capabilities and
                profound insights can enhance your business strategies.
              </div>
            </div>

            <div>
              <div className="title">Careers & Recruitment</div>

              <div className="content">
                {" "}
                Get in touch to be part of our journey in revolutionizing AI and advanced analytics solutions for intricate business challenges.
              </div>
            </div>

            <div>
              <div className="title">Media & Press Relations</div>

              <div className="content">
                {" "}
                Journalists or media professionals seeking authoritative insights in AI and analytics, count on us for credible perspectives.
              </div>
            </div>

            <div>
              <div className="title"> General Inquiries</div>

              <div className="content">
                {" "}
                For any additional information about our services, solutions, or potential collaborations, feel free to send us a message.
              </div>
            </div>
            </div>

           
          </div>

          <div className="contact-flexRight">
            <div>
              <form id="contact-form" onSubmit={submitToAPI}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gridGap: "20px",
                    borderLeft: "2px solid white",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  {/* name */}
                  <div className="form__field" style={{ width: "45%" }}>
                    <input
                      id="name-input"
                      className="input-field-landing font-family"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </div>

                  {/* // phone  */}
                  <div className="form__field" style={{ width: "45%" }}>
                    <input
                      id="phone-input"
                      className="input-field-landing font-family"
                      type="tel"
                      name="phone"
                      placeholder="Phone"
                      required
                    />
                  </div>

                  {/* email  */}
                  <div className="form__field" style={{ width: "45%" }}>
                    <input
                      id="email-input"
                      className="input-field-landing font-family"
                      type="email"
                      name="email"
                      placeholder="Email address"
                      required
                      width="100%"
                    />
                  </div>

                  {/* // company  */}
                  <div className="form__field" style={{ width: "45%" }}>
                    <input
                      id="company-input"
                      className="input-field-landing font-family "
                      type="text"
                      name="company"
                      placeholder="Company"
                      required
                    />
                  </div>

                  {/* // designation  */}
                  <div className="form__field" style={{ width: "45%" }}>
                    <input
                      id="designation-input"
                      className="input-field-landing font-family "
                      type="text"
                      name="designation"
                      placeholder="Designation"
                      required
                    />
                  </div>

                  {/* // Country  */}
                  <div className="form__field" style={{ width: "45%" }}>
                    <input
                      id="designation-input"
                      className="input-field-landing font-family"
                      type="text"
                      name="country"
                      placeholder="Country"
                      required
                    />
                  </div>

                  {/* // textarea  */}
                  <div className="form__field" style={{ width: "100%" }}>
                    <textarea
                      rows={5}
                      id="message-input"
                      className="input-field-landing font-family "
                      name="message"
                      placeholder="Tell us more "
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="btn-read-more" style={{ width: "45%" }}>
                  <button type="submit" disabled={isloading} className="">
                    {isloading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span style={{ paddingLeft: "10px" }}>
                          Submitting...
                        </span>
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {success && (
        <div
          id="successSvg"
          style={{
            width: "47%",
            display: "grid",
            justifyContent: "flex-end",
            position: "absolute",
            right: "11%",
          }}
        >
          <Alert style={{ background: "white" }} severity="success">
            Thank you for getting in touch! We appreciate you contacting us. Our
            analytics expert will get in touch with you soon. Have a great day!
          </Alert>
        </div>
      )}

      {isError && (
        <div
          style={{
            position: "fixed",
            width: "auto",
            top: "12%",
            right: "10px",
            zIndex:"9999"
          }}
        >
          <Alert severity="error">
            Sometime went wrong, Please try again after sometime
          </Alert>
        </div>
      )}

      {/* <Alert severity="error">This is an error alert — check it out!</Alert>
    <Alert severity="success">This is a success alert — check it out!</Alert> */}
    </div>
  );
}

export default Section1;
