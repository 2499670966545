import React, { useEffect } from "react";
import Navbar from "../nav/NavBar";
import { Helmet } from "react-helmet";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import Footer from "../nav/Footer";
import infograpgic1 from "../assets/img/AI-innovations-Infographic-1.png";
import FinanceImg1 from "../assets/img/Top-icons-1.png";
import FinanceImg2 from "../assets/img/Top-icons-2.png";
import FinanceImg3 from "../assets/img/Top-icons-3.png";
import FinanceImg4 from "../assets/img/Top-icons-4.png";
import FinanceImg5 from "../assets/img/Top-icons-5.png";
import newimage from "../assets/img/ai-page-img.png";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import aiLedInnovationMobile from "../assets/img/ai-led-innovation-mobile.png";
import { useMediaQuery } from "@mui/material";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import Section5 from "../pageLayout/AboutPageLayout/Section5";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import mobileBannerImage from "../assets/img/ai-innovation-banner-right.png";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import BottomBanner from "../reuseLayout/BottomBanner";


const Ainnovations = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

   useEffect(() => {
     document.title = "AI Innovations and Enterprise AI| Aays";
    //  document.getElementsByTagName("META")[3].content =
    //    "Explore AI innovations and enterprise AI solutions with our advanced ML and data science strategies, driving impactful results.";
   });
  return (
    <div id="homePage" className="ai-innovation-main">
      <Helmet>
        <meta
          name="keywords"
          content="AI Innovation, AI, Enterprise AI, ML Solutions, Data Science Solutions"
        />
      </Helmet>
      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Revolutionizing Enterprises with "
          text2="AI-led Innovations"
          subtext1="Explainable AI, Contextual AI,"
          subtext2="and Advanced Retrieval Strategies"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Revolutionizing Enterprises with "
          text2="AI-led Innovations"
          subtext1="Explainable AI, Contextual AI,"
          subtext2="and Advanced Retrieval Strategies"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Revolutionizing Enterprises with "
          text2="AI-led Innovations"
          subtext1="Explainable AI, Contextual AI,"
          subtext2="and Advanced Retrieval Strategies"
          picture={mobileBannerImage}
        />
      )}
      <MiddleBanner text="Delivered enterprise-grade, powerful Supply Chain and Finance solutions powered by Gen-AI" />

      <div className="ai-section1-new">
        <div className="margin-box">
          <div className="heading">
            <span style={{ display: "grid" }}>
              <span className="heading-supply-chain">
                Deploying production ready enterprise grade AI projects in a
                large distributed data landscape
              </span>
            </span>
          </div>
          <div className="imgAlignCenter">
            {isMobile ? (
              <img
                loading="lazy"
                style={{
                  width: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
                src={aiLedInnovationMobile}
                alt="infographic1"
              />
            ) : (
              <img
                loading="lazy"
                style={{
                  width: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
                src={infograpgic1}
                alt="infographic1"
              />
            )}
          </div>
        </div>
      </div>

      <div className="ai-section2-new">
        <div className="margin-80">
          <div className="heading">
            <span style={{ display: "grid" }}>
              <h1 className="heading-supply-chain">
                Data Science & ML Powered Solutions
              </h1>
            </span>
          </div>

          <div>
            <div className="GridLayoutNew">
              <div class="flip-card" tabIndex="0">
                <div class="flip-card-inner">
                  <div class="flip-card-front flip-card-food">
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <span>
                        <img
                          loading="lazy"
                          style={{ width: "120px" }}
                          src={FinanceImg1}
                          alt="Food"
                        />
                      </span>
                      <span className="flip-box-header-text">
                        {" "}
                        Forecasting Use Cases{" "}
                      </span>
                    </div>
                  </div>
                  <div class="flip-card-back">
                    <div className="ai-flip-list">
                      <span>Brand and Customer NSV Forecasting</span>
                      <span>Anomaly Detection for Account Quality</span>
                      <span>Treasury Cash Flow Predictions</span>
                      <span>New Product Inventory Predictions</span>
                      <span>Smart Reordering Strategies</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="middle-item">
                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span>
                          <img
                            loading="lazy"
                            style={{ width: "120px" }}
                            src={FinanceImg2}
                            alt="Food"
                          />
                        </span>
                        <span className="flip-box-header-text">
                          Segmentation Scenarios
                        </span>
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <div className="ai-flip-list">
                        <span>OTC Management: Customer Segmentation</span>
                        <span>Anomaly Detection for Account Quality</span>
                        <span>
                          Streamlined Customer Feedback Categorization
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span>
                          <img
                            loading="lazy"
                            style={{ width: "90px" }}
                            src={FinanceImg3}
                            alt="Food"
                          />
                        </span>
                        <span className="flip-box-header-text">
                          NLP Applications
                        </span>
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <div className="ai-flip-list">
                        <span>Sentiment Analysis for Effective Messaging </span>
                        <span>Monthly Topic Evolution Insights</span>
                        <span>GenAI-Powered Information Extraction</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span>
                          <img
                            loading="lazy"
                            style={{ width: "90px" }}
                            src={FinanceImg5}
                            alt="Food"
                          />
                        </span>
                        <span className="flip-box-header-text">
                          Causal AI Insights
                        </span>
                      </div>{" "}
                    </div>
                    <div class="flip-card-back">
                      <div className="ai-flip-list">
                        <span>Quantifying Promotional Impact on Sales</span>
                        <span>
                          Understanding Cannibalization and Incrementality
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      {" "}
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span>
                          <img
                            loading="lazy"
                            style={{ width: "90px" }}
                            src={FinanceImg4}
                            alt="Food"
                          />
                        </span>
                        <span className="flip-box-header-text">
                          Manufacturing
                        </span>
                      </div>{" "}
                    </div>
                    <div class="flip-card-back">
                      <div className="ai-flip-list">
                        <span> Monitoring Early Payment Instances</span>
                        <span>ML-Based Customer Overdue Triggers</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!isIpad && (
                <div
                  class="flip-card lastFlip-card"
                  style={{
                    background: `url(${newimage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  tabIndex="0"
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <Section5
            data={CustomerSuccessStoriesData}
            tag="aiInnovation"
            show="false"
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="aiInnovation"
            show="false"
          />
        )}
      </div>
      <BottomBanner contentChange={true} />

      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default Ainnovations;
