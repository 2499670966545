export const fre1 = [
    {
        id: 1,
        title: " How is AI/ML used in supply chain optimization?",

        description:
          "AI is used in supply chain optimization by analyzing data to identify inefficiencies, predict demand, and automate processes. AI algorithms can optimize routes, manage inventory, and improve decision-making, leading to cost savings and enhanced performance across the supply chain.",
      },
      {
        id: 6,
        title: "How does AI contribute to sustainability and efficiency in supply chains?",
        description:
          "AI contributes to sustainability and efficiency in supply chains by optimizing routes, reducing energy consumption, and minimizing waste. AI systems can analyze data to identify the most efficient transportation routes, manage resources more effectively, and ensure that operations are conducted in an environmentally friendly manner.",
      },
      {
        id: 3,
        title: "How is AI transforming supply chain management?",
        description:
          "AI in supply chain management refers to applying artificial intelligence technologies to optimize and automate supply chain processes. This includes tasks such as demand forecasting, inventory management, logistics, and transportation, all of which benefit from AI's ability to analyze data and make informed decisions.",
      },
      
];
export const fre2 = [

  {
    id: 4,
    title: "How does AI improve demand forecasting in supply chains?",
    description: "AI improves demand forecasting by analyzing large datasets, including historical sales data, market trends, and external factors, to predict future demand more accurately. This allows companies to optimize inventory levels, reduce stockouts, and improve customer satisfaction.",
  },
      
      {
        id: 5,
        title: "What setbacks do firms face when implementing AI in their supply chains?",
        description:
          "Companies face several challenges when implementing AI in their supply chains, including data quality issues, high implementation costs, the complexity of integrating AI systems, and the need for change management and employee training. Addressing these setbacks is essential for successful AI adoption",
      },
    
      {
        id: 2,
        title: "How is ML used in the supply chain? ",
        description:
          "Machine learning (ML) is used in supply chain management to analyze historical data, identify patterns, and make predictions. ML algorithms can enhance demand forecasting, optimize inventory levels, and improve the accuracy of supply chain planning, resulting in more efficient and effective operations.",
      },

];

export const fre5 = [

  {
    id: 16,
    title: "What are the risks of not adopting AI in inventory management for businesses? ",
    description: "Businesses that need to adopt AI in inventory management face risks such as inefficiencies, higher operational costs, and missed sales opportunities due to stockouts or overstocking. Manual processes are slower and prone to errors, leaving companies less agile and unable to respond quickly to market changes or demand shifts",
  },
      
      {
        id: 17,
        title: "How to use AI in warehouse management? ",
        description:
          "AI in warehouse management can automate inventory tracking, optimize storage space, and streamline picking and packing processes. AI systems analyze day-to-day data to ensure accurate stock levels, improve the efficiency of warehouse operations, and reduce human errors, leading to faster order fulfillment and cost savings.",
      },
    
    

];
export const fre8 = [

  {
    id: 11,
    title: "Can AI in inventory management support long-term scalability? ",
    description: "AI-driven inventory management systems are designed to scale with business growth. As companies expand, AI can handle larger datasets, more complex inventory operations, and additional product lines. Its adaptability ensures businesses can meet future demands and maintain efficiency, making AI an essential solution for long-term scalability.",
  },
      
      {
        id: 12,
        title: "Can inventory management be automated?",
        description:
          "Yes, inventory management can be automated using AI. AI-powered systems automate demand forecasting, stock replenishment, and order management, reducing human intervention. These systems ensure accurate stock levels and prevent stockouts or excess inventory, allowing businesses to focus on strategic activities rather than routine operational tasks.",
      },
    
     
];
