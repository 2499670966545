import { frequentlyQuestionsPart1, frequentlyQuestionsPart2 } from "./AccordionData";
import AccordionFile from "./AccordionFile";

const Section9 = () => {
  return (
    <div className="aadi-section9">
      <div className="main">
        <div className="heading"> Frequently Asked Questions</div>
        <div className="content">
          {/* <div className="text">
            Learn why Aays was named as a Seasoned Vendor in the AIM Penetration
            and Maturity Quadrant for Top Gen AI Service Providers 2023
          </div> */}
        </div>
        <div className="flex-container">
          <div className="left">
            <div className="accordian-file">
              <AccordionFile data={frequentlyQuestionsPart1} />
            </div>
          </div>
          <div className="right">
            <div className="accordian-file">
              <AccordionFile data={frequentlyQuestionsPart2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section9;
