import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./Components/assets/css/branding.css";
import "./Components/assets/css/navHeader.css";
import "./Components/assets/css/slider.css";
import "./Components/assets/css/style.scss";
import "./Components/assets/css/footer.css";
import "./Components/assets/css/loader.css";
import "./App.css";
import "./responsive.scss";
import "./Style.scss";
import "./ipadResponsive.scss";


// import "./Components/pageLayout/HomePageLayout/HomePage.scss";
import "./Components/pageLayout/CustomerSuccessStoryLayout/CustomerSuccessStory.scss"
// import "./Components/pageLayout/BlogLayout/Blog.scss";
import "./Components/pageLayout/BlogMoreLayout/BlogMoreLayout.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
