import React, { useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import ai1 from "../../assets/img/ai1.jpg";
import ai2 from "../../assets/img/ai2.png";
import ai3 from "../../assets/img/ai3.png";
import ai11 from "../../assets/img/inventory-mangement-ai.png"
import ai7 from "../../assets/img/preditive-analytics-aifinannce.png";
import ai4 from "../../assets/img/ai4.jpg";
import BlogsAi from "../../assets/img/BlogsAi.jpg";
import ai6 from "../../assets/img/Blogs-ai-trasn.png";
import GenAiBanner from "../../assets/img/transforming-pricing-decisions-in-CPG-companies.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1345 },
    items: 4,
  },
  superdesktop: {
    breakpoint: { max: 1296, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};



const Section3 = () => {
 

  return (
    <div
    id="supplychainss"
    className="blog-success-section2 scroll-section"
  >
    <div className="customer-section2-body margin-box">
      <div className="success-story-page-section-2-Header-text">
      Supply Chain & CPG
      </div>
      <div className="customer-section2-body">
        <div className="section2-box">
          {/* <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
          className="new-cls-blogs"
        > */}

          <Carousel
            responsive={responsive}
            dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
            arrows={false}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            infinite={true}
            slidesToSlide={1}
            swipeable={true}
            draggable={false}
            // showDots={isMobile? false : true}
          >
            {/* <CustomerSectionBox
              picture={ai7}
              title1="Transforming Inventory Management with AI"
              date="Sep 26, 2024 "
              read="2 min read"
              class="content-box-section2a"
              redirectURL="/blogs/business-analytics-architecture"
            /> */}
            <CustomerSectionBox
              picture={ai11}
              title1="Inventory Management with AI: Boost Efficiency, Cut Costs, & Gain Competitive Edge"
              date="Sep 11, 2024"
              class="content-box-section2a"
              read=" 2 min read"
              redirectURL="/blog/ai-inventory-management"
            />
            <CustomerSectionBox
              picture={ai6}
              title1="The Future of Supply Chain Management: How AI is Leading the Way?"
              date="Sep 13, 2024"
              class="content-box-section2a"
              read=" 2 min read"
              redirectURL="/blog/the-future-of-supply-chain-management-how-ai-is-leading-the-way"
            />
            <CustomerSectionBox
              picture={ai1}
              title1="Increase Sales with CPG Pricing Analytics: A 5-10% Boost Opportunity"
              date="Aug 8, 2023"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/transforming-cpg-pricing-analytics"
            />

            <CustomerSectionBox
              picture={ai2}
              title1=" Supply Chain Cost Reduction in CPG Firms through Data-Driven Optimization"
              date="May 4, 2023"
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/cpg-supply-chain-cost-reduction"
            />
              <CustomerSectionBox
              picture={ai3}
              title1="Enhancing Supply Chain Management with Analytics: Cost Reduction and Efficiency"
              date="April 13, 2023 "
              class="content-box-section2a"
              read="2 min read"
              redirectURL="/blog/analytics-in-supply-chain-management"
            />
          </Carousel>
          {/* </div> */}
        </div>
      </div>
    </div>
  </div>
  );
};

export default Section3;
