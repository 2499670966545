import React from 'react'
import Navbar from '../../nav/NavBar'

const FinanceAnalyticsCapabilities = () => {
  return (

    <div  style={{height:"100vh",overflow:"hidden",background:"rgb(82,86,89)"}} id='homePage' className='campaning'>
    <Navbar/>
    <div style={{ width: "100%", height: "100%",paddingTop:"0rem" }}>
    <div style={{marginTop:"5rem"}}></div>

    <iframe
        title="PDF Viewer"
        src={`https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/Market-Mix-Modelling-Analytics.pdf#toolbar=0&navpanes=0&scrollbar=0`}
        width="100%"
        height="100%"
        style={{border:"none"}}

      ></iframe>

    </div>

    </div>
   
  )
}

export default FinanceAnalyticsCapabilities
