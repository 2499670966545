import React from "react";
import { useMediaQuery, useTheme } from "@mui/material/";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import NewSection2 from "./NewSection2";
import NewSection3 from "./NewSection3";

function CareerPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="career-body">
      <Section1 />
      {isMobile ? <Section2 /> : <NewSection2 /> } 
      {!isMobile && <NewSection3 />}
      <Section4 isMobile={isMobile}/>
      <Section5 />
      <Section6 />
      <Section7 />
    </div>
  );
}

export default CareerPage;
