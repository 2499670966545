import React from "react";
import middle from "../../assets/img/career-group.jpg";
import bestFirmBadge from "../../assets/img/best-firm-badge.png";

const boxData = [
  {
    id: 1,
    heading: "Grow With Us",
    background: "#171616",
    font: "30px",
  },
  {
    id: 2,
    image: middle,
    width: "100%",
    background: "#212121",
  },
  {
    id: 3,
    content:
      "Unleash your potential through mentorship, training, and well-defined career paths",
    background: "#212121",
  },
  {
    id: 4,
    image: bestFirmBadge,
    width: "70%",
    background: "#212121",
    padding: "2rem",
  },
  {
    id: 5,
    content:
      "We empower you to carve your own path while making a meaningful impact in the realm of analytics",
    background: "#171616",
  },
  {
    id: 6,
    heading: "Place Where Innovation Thrives",
    background: "#bb1ccc",
    padding: "4rem",
    font: "30px",
  },
];

function NewSection3() {
  return (
    <div className="career-section3-new">
      <div className="grid-box">
        {boxData.map((ele) => (
          <>
            {ele?.heading && (
              <div
                className="heading"
                style={{
                  backgroundColor: ele.background,
                  padding: ele.padding,
                  fontSize: ele.font,
                }}
              >
                {ele.heading}
              </div>
            )}

            {ele?.content && (
              <div
                className="content"
                style={{ backgroundColor: ele.background, fontSize: ele.font }}
              >
                {ele.content}
              </div>
            )}

            {ele?.image && (
              <div
                className="image-box"
                style={{ backgroundColor: ele.background }}
              >
                <img
                  loading="lazy"
                  src={ele.image}
                  alt=""
                  style={{
                    width: ele.width,
                    height: ele.height,
                    padding: ele.padding,
                  }}
                />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

export default NewSection3;
