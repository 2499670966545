import React, { useEffect } from "react";
// import Section1 from "../pageLayout/CustomerSuccessStoryMoreLayout/Section1";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/marketing4.jpg"
import { Helmet } from "react-helmet";


const storyData = {
  title:
    "Optimizing marketing mix strategies for a leading snack foods manufacturer",
  content:
    "A  fast-growing snack foods manufacturer sought to understand the complex interplay of marketing activities and their impact on sales. Market Mix Modeling was employed to analyze revenue generation, including promotions, distribution, competition, advertising, sponsorships, seasonality etc. This approach uncovered $10 million in budget optimization, a 5% sales boost, and the potential for a 15% ROI increase for their $130 million annual marketing investments",
  subTitle1: "$10 million",
  subText1: "Marketing budget optimization ",
  subTitle2: "15%",
  subText2: "Improvement in ROI",
  imageData: picture
};

function Optimisingmarketmixedstrats () {

  useEffect(() => {
    document.title = "Efficient Vendor Risk Management | Consumer Goods Case Study";
    const metaTags = document.getElementsByTagName("META");
    if (metaTags.length > 3) {
      metaTags[3].content = "A global CPG company efficiently managed vendor risks, finding 40,000 vendor risk profiles, ensuring proactive risk mitigation strategies and cost savings of $2Mn.";
    }
  });

  return (
    <div className="backround-black">
      <Helmet>
        <meta
          name="keywords"
          content="Vendor risk management, supply chain risks, consumer goods manufacturer, vendor risk profiles."
        />
      </Helmet>

      <div>
        <Indicusstomersuccessstories data={storyData} />
      </div>

      {/* <Resources /> */}
      <Footer />
    </div>
  );
}

export default Optimisingmarketmixedstrats;
