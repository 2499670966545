import React from "react";
import { ReactComponent as ReadMoreIcon } from "../../assets/img/read-more-arrow.svg";

function Section2Mobile(props) {
  const [firstItem, ...restItems] = props?.data;

  return (
    <div className="customer-success-section2-mobile">
      <div className="Header-text">{props?.heading}</div>

      {/* First Box */}
      <div className="first-box">
        <div className="image-div">
          <img src={firstItem.image} alt={firstItem.heading} width="100%" />
        </div>
        <div className="box-heading">{firstItem.heading}</div>
        <div className="box-content">{firstItem.content}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <a
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            className="read-more-icon"
            href={`${firstItem.redirectURL}`}
          >
            <ReadMoreIcon width="40px" height="40px" />
          </a>
        </div>
      </div>

      {/* Second Box */}
      {restItems.map((item) => (
        <a href={item.redirectURL} key={item.id} className="second-box">
          <img src={item.image} alt={item.heading} width="100%" />
          <div className="box-detail">
            <div className="box-heading">{item.heading}</div>
            <div className="box-content">{item.content}</div>
          </div>
        </a>
      ))}
    </div>
  );
}

export default Section2Mobile;
