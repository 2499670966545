import { Helmet } from "react-helmet";
import { useState } from "react";
import $ from "jquery";
import Navbar from "../../../nav/NavBar";
import "./newBlog.scss";
import "./newBlogResponsive.scss";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import KeyPoints from "./Keypoints";
import { KeyPointGenAIData } from "./KeyPointData";
import aaysInGenai from "../../../assets/img/genai/aays-in-genai.png";
import badge from "../../../assets/img/genai/badge-01.png";
import logo from "../../../assets/img/logo.png";
import Footer from "../../../nav/Footer";
import { useMediaQuery, useTheme } from "@mui/material/";
import { useEffect } from "react";

const Genai = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isloading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);

  useEffect(() => {
    document.title = "Aays | Ranked Seasoned Vendor for Gen AI";
    document.getElementsByTagName("META")[3].content =
      "Aays recognized as a Seasoned Vendor in AIM Research's Gen AI PeMa Quadrant. Discover our leading Gen AI services and expertise highlighted in the Gen AI Report.";
  });

  const rejectList = ["effem.com"];

  const downloadFinanceController = async () => {
    console.log("download function called");
    try {
      const response = await fetch(
        "https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/Top-Generative-AI-Service-Providers-2023.pdf"
      );

      console.log(response, "res");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      console.log(url, "url");
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute(
        "download",
        `Top-Generative-AI-Service-Providers-2023-${new Date().getTime()}.pdf`
      );

      document.body.appendChild(link);
      console.log(link, "link");
      link.click();
    } catch (error) {
      console.error("Error downloading finance controller:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    var URL =
      "https://kixepycste.execute-api.us-east-1.amazonaws.com/prod/contactus";

    var name = $("#fullName").val();
    var phone = "No phone number";
    var email = $("#email").val();
    var desg = $("#designation").val();
    var message = "";
    var company = $("#companyName").val();
    var splitArray = email.split("@");
    if (rejectList.indexOf(splitArray[1]) >= 0) {
      message = "View market modelling case study restricted";
    } else {
      // Remove ".html" extension

      message = "View Market Modelling case study";
    }

    var contactUsData = {
      name: name,
      phone: phone,
      email: email,
      designation: desg,
      message: message,
      company: company,
    };

    $.ajax({
      type: "POST",
      url: URL,
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(contactUsData),

      success: function () {
        console.log("successfully submittedddd");
        // clear form and show a success message
        setisLoading(false);

        if (rejectList.indexOf(splitArray[1]) >= 0) {
          console.log("rejected");
          setisError(true);
          setTimeout(() => {
            setisError(false);
          }, 5000);
          return false;
        } else {
          console.log("view");
          downloadFinanceController();
        }
      },
      error: function () {
        setisError(true);
        setisLoading(false);

        setTimeout(() => {
          setisError(false);
        }, 5000);
      },
    });
  };

    const data = [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "All Blogs",
        url: "/Blog",
      },
    ];

  return (
    <>
      <div id="homePage">
        <Helmet>
          <meta
            name="keywords"
            content="Aays Gen AI, Seasoned Vendor AIM Research, Gen AI PeMa Quadrant, Gen AI Report, Gen AI Services"
          />
        </Helmet>
        <Navbar bg="black" />
        <div className="newBlog-body">
          <div className="newBlog-margin">
            <div className="newBlog-main">
              <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
              <div className="firstSection">
                <div className="main">
                  <h1 className="heading">
                    Aays Recognized as 'Seasoned Vendor' in Gen AI Report by AIM
                    Research
                  </h1>
                  <div className="date-read-container">
                    <div className="date-container">
                      <div className="date"> Aug 6, 2023 </div>
                      <div className="read">
                        <AccessTimeIcon
                          style={{
                            fontSize: "14px",
                          }}
                        />{" "}
                        <span> 2 min read</span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="box-form">
                    <div className="left">
                      <div className="badge">
                        <img src={badge} alt="Aays Gen AI" />
                        {/* <div className="content">
                          Recognized by AIM <br /> as a Seasoned Vendor{" "}
                        </div> */}
                      </div>
                    </div>
                    {!isMobile && (
                      <div className="right">
                        <div className="genai-form">
                          <div className="aays-logo">
                            <img src={logo} alt="Aays" />
                          </div>
                          <form className="form" onSubmit={handleSubmit}>
                            <input
                              placeholder="Full Name"
                              type="text"
                              name="fullName"
                              id="fullName"
                              required
                            />
                            <input
                              placeholder="Email Address"
                              type="email"
                              name="email"
                              id="email"
                              required
                            />
                            <div className="same-line">
                              <input
                                placeholder="Company Name"
                                type="text"
                                name="companyName"
                                id="companyName"
                                required
                              />
                              <input
                                placeholder="Designation"
                                type="text"
                                name="designation"
                                id="designation"
                                required
                              />
                            </div>

                            <div className="button">
                              <button>Download Report</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="content1">
                    <div className="text1">
                      We're thrilled to announce our inclusion in the "Seasoned
                      Vendors" quadrant of Gen AI report by AIM Research.
                    </div>
                    <div className="text1">
                      This recognition comes as validation of our dedication to
                      pushing boundaries in innovation, ensuring our clients'
                      satisfaction, and delivering technical expertise in the
                      realm of analytics and Gen AI.
                    </div>
                  </div>
                </div>
              </div>

              <div className="secondSection">
                <div className="main">
                  <div className="heading">
                    Understanding the <span>AIM PeMa Quadrant</span>
                  </div>
                  <div className="container">
                    <div className="left">
                      <div className="content">
                        <p className="content1">
                          The PeMa Quadrant provides enterprises with a
                          comprehensive view of the market, enabling them to
                          compare and contrast the capabilities of various
                          vendors. This is AIMResearch’s tool designed to help
                          businesses navigate this complex landscape.{" "}
                        </p>
                        <p className="content2">
                          By evaluating service providers across various
                          dimensions, the Quadrant assists in making informed
                          decisions, ensuring that businesses partner with
                          providers that align with their unique requirements.
                        </p>
                      </div>
                      {/* {!isMobile && (
                        <div className="lastLine">
                          Read the complete report{" "}
                          <span>
                            <a
                              href="https://aimresearch.co/2023/11/16/top-generative-ai-service-providers-pema-quadrant-2023/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              here
                            </a>
                          </span>
                        </div>
                      )} */}
                    </div>
                    <div className="right-img">
                      <img src={aaysInGenai} alt="aays" />
                    </div>
                  </div>
                  {/* {isMobile && (
                    <div className="lastLine">
                      Read the complete report{" "}
                      <span>
                        <a
                          href="https://aimresearch.co/2023/11/16/top-generative-ai-service-providers-pema-quadrant-2023/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>
                      </span>
                    </div>
                  )} */}
                </div>
              </div>

              <div className="thirdSection">
                <div className="main">
                  <div className="heading">
                    Aays’ Key Competencies and Differentiators as highlighted in
                    the report
                  </div>
                  <div className="content" style={{fontWeight:'500',lineHeight:'1.5'}}>
                    Different factors are highlighted within the report that
                    underscore Aays' competencies and key differentiators. Here
                    ‘Aays’ is referred to as the ‘vendor’.
                  </div>
                  <div className="text">Snippet from the report:</div>

                  <div className="points">
                    {KeyPointGenAIData.map((data) => (
                      <KeyPoints
                        id={data.id}
                        title={data.title}
                        description={data.description}
                        image={data.image}
                      />
                    ))}
                  </div>
                  <div className="lastPoint">
                    The report was originally published in{" "}
                    <span>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://aimresearch.co/2023/11/16/top-generative-ai-service-providers-pema-quadrant-2023/"
                      >
                        Gen AI
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              {isMobile && (
                <div className="right">
                  <div className="genai-form">
                    <div className="aays-logo">
                      <img src={logo} alt="Aays" />
                    </div>
                    <form className="form" onSubmit={handleSubmit}>
                      <input
                        placeholder="Full Name"
                        type="text"
                        name="fullName"
                        id="fullName"
                        required
                      />
                      <input
                        placeholder="Email Address"
                        type="email"
                        name="email"
                        id="email"
                        required
                      />
                      <div className="same-line">
                        <input
                          placeholder="Company Name"
                          type="text"
                          name="companyName"
                          id="companyName"
                          required
                        />
                        <input
                          placeholder="Designation"
                          type="text"
                          name="designation"
                          id="designation"
                          required
                        />
                      </div>

                      <div className="button">
                        <button>Download Report</button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Genai;
