import React, { useState } from "react";
import { Typography } from "@mui/material";
import robo from "../../assets/img/top-new.png";
import handWithBrain from "../../assets/img/handWithBrain-new.png";
import roboWithBrain from "../../assets/img/roboWithBrain-new.png";
import plantWithHand from "../../assets/img/plant-new.png";
import grid from "../../assets/img/bottom-new.png";
import ParticleBackground from "../../particlesLayout/Particles";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContactUs from "./ContactUs";


function LandingPage() {
  const [openModal, setOpenModal] = useState(false);

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + (90 * $(window).height()) / 100);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - (90 * $(window).height()) / 100);
  };

  return (
    <>
      <div className="landingPage" id="homePage">
        <ParticleBackground />

        <div className="box1">
          <div className="typewriter-container">
            <Typography
              sx={{
                fontSize: "40px",
              }}
              id="output"
              className="typewriter-text font-family h2-cls-subHeader"
              variant="h2"
            >
              Explainable
            </Typography>
            <Typography
              sx={{
                fontSize: "48px",
                // color: "#bb1ccc",
              }}
              id="output"
              className="typewriter-text font-family h2-cls-Header"
              variant="h2"
            >
              <b>AI for Finance</b>
            </Typography>
          </div>
        </div>

        <div className="box3">
          <div className="typewriter-container">
            <Typography
              sx={{
                fontSize: "40px",
              }}
              id="output"
              className="typewriter-text font-family h2-cls-subHeader"
              variant="h2"
            >
              AI-enabled
            </Typography>
            <Typography
              sx={{
                fontSize: "48px",
                // color: "#bb1ccc",
              }}
              id="output"
              className="typewriter-text font-family h2-cls-Header"
              variant="h2"
            >
              <b>Supply Chain</b>
            </Typography>
          </div>
        </div>

        <div className="box5">
          <div className="typewriter-container">
            <Typography
              sx={{
                fontSize: "40px",
              }}
              id="output"
              className="typewriter-text font-family h2-cls-subHeader"
              variant="h2"
            >
              Deploy
            </Typography>
            <Typography
              sx={{
                fontSize: "48px",
                // color: "#bb1ccc",
              }}
              id="output"
              className="typewriter-text font-family h2-cls-Header"
              variant="h2"
            >
              <b> AI at Scale</b>
            </Typography>
          </div>
        </div>

        <div className="box7">
          <div className="typewriter-container">
            <Typography
              sx={{
                fontSize: "40px",
              }}
              id="output"
              className="typewriter-text font-family h2-cls-subHeader"
              variant="h2"
            >
              Embracing AI
            </Typography>

            <Typography
              sx={{
                fontSize: "48px",
                // color: "#bb1ccc",
              }}
              id="output"
              className="typewriter-text font-family h2-cls-Header"
              variant="h2"
            >
              <b>for ESG Initiatives</b>
            </Typography>
          </div>
        </div>

        {/* click to know more Button */}
        <button
          className="buttonWrapper font-family"
          onClick={() => setOpenModal(true)}
        >
          <span>
            <b>Contact Us</b>
          </span>{" "}
          {/* <CallMadeIcon
            sx={{
              fontSize: "20px",
            }}
          /> */}
        </button>

        <ContactUs openModal={openModal} setOpenModal={setOpenModal} />

        <div className="box2">
          <img
            loading="lazy"
            src={roboWithBrain}
            alt=""
            className="animationImages"
          />
        </div>

        <div className="box4">
          <img
            loading="lazy"
            style={
              {
                // width: "500px",
              }
            }
            src={robo}
            alt=""
            className="animationImages"
          />
        </div>

        <div className="box6">
          <img
            loading="lazy"
            style={
              {
                // width: "700px",
              }
            }
            src={grid}
            alt=""
            className="animationImages"
          />
        </div>

        <div className="box8">
          <img
            loading="lazy"
            src={handWithBrain}
            alt=""
            className="animationImages"
          />
        </div>

        <div className="box10">
          <img
            loading="lazy"
            src={plantWithHand}
            alt=""
            className="animationImages"
          />
        </div>
        
      </div>

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
     
      
    </>
  );
}

export default LandingPage;
