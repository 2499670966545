import blog1 from "../assets/img/blog1.png";
import blog2 from "../assets/img/blog2.jpg";
import blog3 from "../assets/img/blog3.jpg";
import aiEngineeringExcellence from "../assets/img/AI Engineering Excellence 1.jpg";
import aiLedInnovation from "../assets/img/AI-led Innovations 1.jpg"
import genai from "../assets/img/Gen AI.square.jpg"

export const ResourceData = [
  {
    id: 1,
    title: " Data Analytics Implementation Journey in Business and Finance ",
    date: "March 31, 2023",
    read: "2 min read",
    class: "content-box-section2a",
    redirectURL:
      "/blogs/data-analytics-implementation-journey-in-business-and-finance",
    picture: blog1,
    category: "resources",
  },
  {
    id: 2,
    title: " How is AI transforming FP&A in the consumer goods industry?",
    date: "March 3, 2023",
    read: "5 min read ",
    class: "content-box-section2a",
    redirectURL:
      "/blogs/how-is-ai-transforming-fp-a-in-the-consumer-goods-industry",
    picture: blog2,
    category: "resources",
  },
  {
    id: 3,
    title: " Transforming Finance Function with Advanced Analytics",
    date: "January 29, 2023",
    read: "2 min read",
    class: "content-box-section2a",
    redirectURL: "/blogs/transforming-finance-function-with-advanced-analytics",
    picture: blog3,
    category: "resources",
  },
  {
    id: 4,
    title: "Enterprise Grade AI Projects Deployed",
    date: "January 29, 2023",
    read: "2 min read",
    class: "content-box-section2a",
    redirectURL: "/ai-innovations-enterprise-solutions",
    picture: aiLedInnovation,
    category: "aadi",
  },
  {
    id: 5,
    title:
      " Aays Recognized as 'Seasoned Vendor' in Gen AI Report by AIM Research",
    date: "Aug 6, 2023",
    read: "2 min read",
    class: "content-box-section2a",
    redirectURL: "/gen-ai-seasoned-vendor-aim-research",
    picture: genai,
    category: "aadi",
  },
  {
    id: 6,
    title: " Delivered ~ 2 PB of AI Engineering Projects",
    date: "January 29, 2023",
    read: "2 min read",
    class: "content-box-section2a",
    redirectURL: "/ai-and-data-engineering",
    picture: aiEngineeringExcellence,
    category: "aadi",
  },
];
