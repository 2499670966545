import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const Acdion = ({ datas }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {datas &&
        datas.map((item) => (
          <Accordion
            expanded={expanded === `panel${item.id}`}
            onChange={handleChange(`panel${item.id}`)}
            key={item.id}
            sx={{
              background: "transparent",
              color: "black",
              borderBottom: "1px solid #212121",
              marginBottom: "10px",
              zIndex: "1",
            }}
          >
            <AccordionSummary
              className="accordian-title"
              expandIcon={<ExpandMoreIcon sx={{ color: "#bb1ccc", fontSize: "38px" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
               // fontWeight: "bold",//
                padding: "0px",
                fontSize: "18px",
                lineHeight: "22.82px",
                fontWeight: "600",
               
              
              }}
              
            >
              {item.title}
            </AccordionSummary>
            <AccordionDetails
              className="accordian-description"
              sx={{
                padding: "0px 0px 10px 0px",
                fontSize: "18px",
                lineHeight: "26.82px",
                fontWeight: "500",
                
              }}
            >
              {item.description}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default Acdion;
