import React from "react";
import { useMediaQuery } from "@mui/material";

import AnshumanCeo from "../../assets/img/anshuman_ceo.png";


import Anilll from "../../assets/img/Anilll.png";
import Dwarikaaa from "../../assets/img/Dwarikaaa.png";
  import Avinashhh from "../../assets/img/Avinashhh.png";
  import gauravagarwal from "../../assets/img/gauravagarwal.png";
  import bhumits from "../../assets/img/bhumits.png"
  import deepak from "../../assets/img/deepak.png";
import Ajithhh from "../../assets/img/Ajithhh.png";
import Layer_5 from "../../assets/img/Layer_5.png";
import linkedinss from "../../assets/img/linkedinss.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Section4.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1900 },
    items: 5,
    slidesToSlide: 1,
    
    
    
   
  },
  tablet: {
    breakpoint: { max: 1900, min: 1200 },
    items: 4,
    slidesToSlide: 4,
   
  },
  mobile: {
    breakpoint: { max: 1200, min: 1000},
    items: 3,
    slidesToSlide: 3,
   
  },
  supermobile: {
      breakpoint: { max: 1000, min: 770 },
      items: 2,
      slidesToSlide: 2,
     
    },
    supers: {
      breakpoint: { max: 770, min: 0 },
      items: 1,
      slidesToSlide: 1,
      
    },
    
};

const filteredData = [
  {
    id: 1,
    image: AnshumanCeo,
    name: "Anshuman Bhar",
    role: "Co-Founder and CEO",
    content:
      "15+ years of experience in strategic consulting and technology, supporting CXOs with enterprise analytics and corporate planning. Holds an MBA from IIM Indore and a B.Tech from IIT Dhanbad. ",
      linkedin:  Layer_5,
      linkedinss: linkedinss,
      linkedinUrl: "https://www.linkedin.com/in/anshuman-bhar/",
  },
  {
    id: 2,
    image: Dwarikaaa,
    name: "Dwarika Patro",
    role: "Co-Founder and COO",
    content:
      " A technology strategist with 20 years of experience in professional services, guiding clients in digital transformation. Holds a B.Tech from IIT-Dhanbad and an MBA from Imperial College London.",
      linkedin:  Layer_5,
      linkedinss: linkedinss,
      linkedinUrl: "https://www.linkedin.com/in/dwarikanath/",
  },

 
  {
    id: 3,
    name: "Avinash Sidha",
    role: "VP - Operations ",
    image: Avinashhh,
    content:
      "11+ years in business operations across verticals. Excels in delivering high-budget product implementations and has a techno-functional background. Holds an MBA from Imperial College London.",
      linkedin:  Layer_5,
      linkedinss: linkedinss,
      linkedinUrl: "https://www.linkedin.com/in/avinashsidha/",
  },
  {
    id: 4,
    name: "Anil Kumar",
    role: "VP - Analytics",
    image: Anilll,
    content:
      "12+ years of experience in automotive solutions and consulting, focusing on analytics-driven transformations. Holds an MBA from AIM, Philippines, and a BE in Mechanical Engineering from ITM Gurgaon",
      linkedin:  Layer_5,
      linkedinss: linkedinss,
      linkedinUrl: "https://www.linkedin.com/in/anil-kumar-%E0%A4%85%E0%A4%A8%E0%A4%BF%E0%A4%B2-%E0%A4%95%E0%A5%81%E0%A4%AE%E0%A4%BE%E0%A4%B0-6845892a/"
  },
  {
    id: 5,
    name: "Gaurav Aggarwal",
    role:'VP - Finance Analytics',
    image: gauravagarwal,
    content:
    "He specializes in designing global finance processes and ERP solutions. With expertise in finance transformation, he drives operational excellence for clients across India, USA, Europe, and UK.",
    linkedin: Layer_5,
    linkedinss: linkedinss,
    linkedinUrl: "https://www.linkedin.com/in/gaurav-agarwal-a7a52a13/"


},
   
  {
    id: 6,
    name: "Bhumit Shah ",
    role: "AVP - Analytics ",
    image: bhumits,
    content:
      "10+ years of experience in leading global teams and transforming business processes with data-driven insights. With deep expertise in CPG, he drives business efficiency and growth for key clients at Aays.",
      linkedin:  Layer_5,
      linkedinss: linkedinss,
      linkedinUrl: "https://www.linkedin.com/in/bhumitshah1/"
  },
 
  {
    id: 7,
    name: "Deepak Mittal ",
    role: "AVP - Azure Cloud Architect ",
    image: deepak,
    content:
      "With 15+ years of experience in data science, engineering, and cloud architecture, he has held key positions in the past and has co-founded multiple ventures. He holds a B.Tech. from IIT (ISM) Dhanbad.",
      linkedin:  Layer_5,
      linkedinss: linkedinss,
      linkedinUrl: "https://www.linkedin.com/in/mittaldeepakiit/"
  },
  {
    id: 8,
    name: "Ajith Sasidharan",
    role: "Principal Data Architect ",
    image: Ajithhh,
    content:
      "Principal Data Architect with 17 years of experience across major companies. Specializes in translating business needs into scalable technical solutions and advising CXOs on data and cloud architecture.",
      linkedin:  Layer_5,
      linkedinss: linkedinss,
      linkedinUrl: "https://www.linkedin.com/in/ajith-sasidharan/"
  },
 
  

];

function Section4() {
  const isMobile = useMediaQuery("(max-width: 1000px)");
  
  return (
    <div className="about-section4 padding-both ">
      <div className="header-text">Meet Our Leadership Team</div>

    <div className="carous">
      <Carousel
       responsive={responsive}
        slidesToSlide={1} 
         dotListClass="custom-dot-list-style"
         arrows={false}
         showDots={isMobile ? false : true}
        
         autoPlay={true}
         autoPlaySpeed={3000}
         keyBoardControl={true}
         infinite={true}
        
         swipeable={true}
         draggable={false}
         containerClass="carousel-container"
          itemClass="itemClass"
         
      >
       
       { filteredData.map((d)=>(
            <div  key={d.id} className="mainss-section">
              <div className="mass">
               <div className='mainsss-imagess'>
                <img src={d.image} alt="leaders" className='mainssimagess '/>
                </div>
            <div className='mains-in'>
            <p className='section-name'>{d.name}</p>
            </div>

<p className='section-role'>{d.role}</p>
             
                <div>
                
             </div>
            </div>
            <div className='section-border'></div>
            <div className='section-contents'>

                <p className="sections-cont">{d.content}</p>
                <a href={d.linkedinUrl} target="_blank" rel="noopener noreferrer"  className="link-wrapper">
                <img src={d.linkedin} alt="insta" className="linkk normal-img" />
                <img src={d.linkedinss} alt="instas" className="linkk pink-img" />
                </a>
               
            </div>
            </div>
             
           
        ))
       
       
      

        }
        </Carousel>
        </div>
        </div>
       

      
    
  );
}

export default Section4;