import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material/";
import HomePageSection1 from "../pageLayout/HomePageLayout/HomePageSection1";
import HomePageSection2 from "../pageLayout/HomePageLayout/HomePageSection2";
import HomePageSection3 from "../pageLayout/HomePageLayout/HomePageSection3";
import HomePageSection4 from "../pageLayout/HomePageLayout/HomePageSection4";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import CustomerSuccessSection from "../pageLayout/AboutPageLayout/Section5";
import Navbar from "../nav/NavBar";
import OurTeams from "../reuseLayout/OurTeams";
import Footer from "../nav/Footer";
import "../pageLayout/HomePageLayout/HomePage.scss";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import AccoladesAndRecognitions from "../pageLayout/HomePageLayout/AccoladesAndRecognitions";
import BottomBanner from "../reuseLayout/BottomBanner";

const subheading =
  "Our AI and analytics solutions span diverse industries and functions, offering a vast array of capabilities to empower your success. We're your guide through toughest challenges, ensuring confidence in every step.";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // useEffect(() => {
  //   const script1 = document.createElement('script');
  //   script1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-342608069';
  //   script1.async = true;
  //   document.head.appendChild(script1);

  //   const script2 = document.createElement('script');
  //   script2.innerHTML = `
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //     gtag('js', new Date());
  //     gtag('config', 'AW-342608069');
  //   `;
  //   document.head.appendChild(script2);

  //   const script3 = document.createElement('script');
  //   script3.innerHTML = `
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //     gtag('js', new Date());

  //     gtag('config', 'AW-342608069');
  //   `;
  //   document.head.appendChild(script3);

  //   // Cleanup scripts on component unmount
  //   return () => {
  //     document.head.removeChild(script1);
  //     document.head.removeChild(script2);
  //     document.head.removeChild(script3);
  //   };
  // }, []);

  return (
    <div id="HomMain" className="homepage-mobile">
      <Navbar />
      <GoogleAnalytics/>
      <HomePageSection1 />
      {/*<MiddleBanner text="Delivered business impact of $2Bn using AI and data led solutions" /> */}
     
      <AccoladesAndRecognitions className="home-main" />
     
      <HomePageSection2 />
      {/* <HomePageSection3 /> */}
      <HomePageSection4 heading="AI-Driven Solutions" subheading={subheading} />
      <OurTeams />

      <div
        style={{
          backgroundColor: "black",
          //position: "relative",//
        }}
      >
        {!isMobile ? (
          <CustomerSuccessSection
            data={CustomerSuccessStoriesData}
            tag="home"
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="home"
            // visibility="show"
          />
        )}
      </div>
      <BottomBanner contentChange={true} removeNetwork = {true} />
      <Footer />
    </div>
  );
};

export default Home;
